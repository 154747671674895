.exception-layout {
  .details-section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    .title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.4375;
      color: var(--text-primary-color);
      display: flex;
      align-items: center;
    }

    .controls {
      display: flex;
      margin-right: -0.625rem;
    }

    .control-btn {
      color: var(--primary-color);
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.428;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      margin: -0.5rem 0;

      .rkt-icon {
        font-size: 1.25rem;
      }

      &:hover {
        color: var(--btn-link-hover-color);
      }
    }
  }

  .view-history-btn {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--primary-color);
    font-size: 0.875rem;
    line-height: 1.428;
    font-weight: 400;
    padding: 0.25rem 0.375rem;
    border-radius: 0.5rem;
    margin: 1rem -0.375rem -0.25rem;

    .rkt-icon {
      font-size: 1.3125rem;
    }

    &:hover {
      color: var(--btn-link-hover-color);
    }
  }

  .details-col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.exception-layout-full-width {
  margin-bottom: 1.25rem;
}

.exception-layout-with-side-panel {
  display: flex;
  gap: 1.25rem;

  .exception-layout-section:nth-child(1) {
    flex: 1;
    min-width: 0;
  }

  .exception-layout-section:nth-child(2) {
    width: 22rem;
  }
}

.exception-history-modal {
  --modal-content-width: 37.5rem;

  .modal-body {
    padding: 1.75rem 2.25rem;
  }

  .modal-title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    color: var(--text-primary-color);
  }

  .modal-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .empty-list-message {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.428;
    color: var(--text-tertiary-color);
    margin: 2.5rem 0;
  }
}

.exception-history-items {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 1.25rem;

  .history-item {
    display: flex;
    gap: 1.25rem;
    align-items: center;
  }

  .history-item:not(:last-child) {
    padding-bottom: 1.25rem;
    border-bottom: 1px solid var(--default-border-color);
  }

  .history-item-date {
    font-size: 0.75rem;
    line-height: 1.416;
    font-weight: 600;
  }

  .history-item-descr {
    font-size: 0.875rem;
    line-height: 1.428;
    font-weight: 400;
  }
}
