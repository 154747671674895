.card-account-details-page {
  .details-section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    .title {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.375;
      color: var(--text-primary-color);
      display: flex;
      align-items: center;
    }

    .controls {
      display: flex;
      margin-right: -0.625rem;
    }

    .control-btn {
      color: var(--primary-color);
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.428;
      padding-left: 0.625rem;
      padding-right: 0.625rem;

      &:hover {
        color: var(--btn-link-hover-color);
      }
    }
  }

  .dispute-description,
  .reissue-reason {
    margin-top: 1rem;
    color: var(--text-primary-color);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.428;

    &.not-available {
      color: var(--value-empty-color);
    }
  }

  .create-update-section {
    .details-row:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .details-separator {
    border-top: 1px solid var(--default-separator-color);
    margin-top: 1.25rem;
    padding-top: 1.25rem;
  }

  .details-description-item {
    display: grid;
    grid-template-columns: 11.25rem 1fr;
    gap: 1.25rem;

    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  .description-item-title-container {
    .rkt-button {
      padding: 0;
      color: var(--primary-color);

      &:hover {
        color: var(--btn-link-hover-color);
      }
    }
  }

  .description-item-title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375;
    margin-bottom: 8px;
    color: var(--text-primary-color);
    white-space: nowrap;
  }

  .description-item-content-container {
    border: 1px solid var(--default-border-color);
    border-radius: 8px;
    display: flex;
    padding: 0.75rem 1rem;
    gap: 1.25rem;
  }

  .description-item-content {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    flex: 1;
    min-width: 0;

    .content-author {
      font-weight: 400;
      font-size: 0.8125rem;
      line-height: 1.5;
      color: var(--text-secondary-color);
    }

    .content-text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.428;
      color: var(--text-primary-color);
    }
  }

  .description-item-controls {
    min-width: 10.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 0.5rem;
  }

  .description-item-actions {
    display: flex;
    gap: 0.5rem;
  }

  .description-item-button {
    font-size: 1.25rem;
    flex-shrink: 0;
  }

  .description-item-edit {
    color: var(--primary-color);
  }

  .description-item-delete {
    color: var(--error-color);
  }

  .description-item-button:hover {
    border-radius: 50%;
    background: var(--action-button-hover-bg-color);
    outline: 2px solid var(--action-button-hover-bg-color);
  }

  .description-item-date {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.416;
    text-align: right;
    color: var(--text-tertiary-color);
  }
}
