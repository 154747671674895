@import 'variables';

.rkt-menu {
  z-index: $zIndexUserMenu;
  box-shadow: $box-shadow-default $primary-black-56;

  .rkt-menu-item-link {
    height: 3rem;
    color: $primary-white;
    padding: 0.25rem 0.8125rem;

    .rkt-menu-item-text {
      font-size: $font-size-xxs;
      font-weight: 300;
    }
  }

  .rkt-menu-item-link:hover {
    background: $primary-white-04;
    color: $ux-teal-green;

    .rkt-menu-item-text {
      font-weight: 700;
    }
  }

  .rkt-menu-item-icon {
    display: none;
  }
}

.rkt-menu.user-dropdown {
  border: solid 1px $primary-white-20;
  background-color: $ux-charcoal-black;
  padding: 0;
  position: fixed;
  display: block !important;
  top: 3.75rem !important;
}

.rkt-menu.report-dropdown {
  border: solid 1px $ux-gray2;
  background-color: $primary-white;
  padding: 0;
  display: block !important;

  .rkt-menu-item-link {
    color: $primary-black;
  }

  .rkt-menu-item-text {
    font-size: 0.9375rem;
    font-weight: 400;
  }
}
