@import 'colors';

.find-report-form {
  .rkt-form-field {
    margin-top: 0.25rem;
  }
}

.find-report-date-from {
  .rkt-form-label {
    position: absolute;
    top: -1.25rem;
  }
}

.find-report-date-to {
  .rkt-form-label {
    font-size: 0.9375rem;
    font-weight: 400;
    top: 0.5rem;
    left: -1rem;
    position: absolute;
  }
}

.document-list-item {
  width: 100%;
  height: 4.25rem;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  color: $primary-black;
  border: 1px solid $ux-gray2;
  display: flex;
  justify-content: space-between;
}

.document-list-item:last-child {
  margin-bottom: 1.25rem;
}
