.modal-overlay {
  display: block;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.modal-overlay:not(.is-open) {
  display: none;
}

.modal-backdrop {
  background: rgb(188 188 188 / 40%);
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 2.5rem);
  margin: 1.25rem;
}

rkt-modal + rkt-modal {
  .modal-backdrop {
    z-index: 1000;
  }

  .modal-overlay {
    z-index: 1001;
  }
}
