.velocity-restriction-list {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.velocity-restriction-list-item {
  background: var(--white-color);
  border-radius: 8px;
  padding: 0.9375rem 1.6875rem;
  border: 1px solid var(--white-color);
  transition: all 0.1s linear;
  display: flex;
  flex-direction: column;
  flex: 1 1 33%;
}

.velocity-restriction-limits {
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  gap: 0.25rem;
}

.velocity-restriction-limit {
  display: flex;
}

.velocity-restriction-limit:not(:first-child, :last-child) {
  border-bottom: 1px solid var(--default-separator-color);
}

.velocity-restriction-limit-item {
  flex: 1;
  white-space: nowrap;
  padding: 0.25rem 0.5rem;
}

.velocity-restriction-list-item-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .action-button {
    padding: 0.25rem;
    font-size: 1.25rem;
    position: absolute;
    right: -12px;
    top: -8px;
    color: var(--primary-color);

    &:not(:disabled):hover {
      background: var(--action-button-hover-bg-color);
      border-radius: 50%;
      box-shadow: 0 0 0 2px var(--action-button-hover-bg-color);
    }
  }
}

.velocity-restriction-limit-header {
  font-weight: 600;
  background: rgb(43 159 173 / 10%);
  border-radius: 4px;
}

.velocity-restriction-modal {
  .modal-content {
    --modal-content-width: 800px;
  }
}

.velocity-restriction-form {
  .repeat-section-item-wrapper {
    position: relative;
    padding-top: 1.25rem;
    margin-top: 1.25rem;
    border-top: 1px solid var(--default-separator-color);

    &:first-child .repeat-section-remove-btn {
      top: 0;
    }
  }

  .rkt-form-repeat-section-item {
    flex-direction: column;
    position: relative;

    &:not(:first-child) {
      padding-top: 1.25rem;
      margin-top: 1.25rem;
      border-top: 1px solid var(--default-separator-color);
    }
  }

  .rkt-form-row-group {
    width: 100%;
  }

  .rkt-form-repeat-section-item .repeat-section-remove-btn {
    display: none;
  }

  .rkt-form-row-group:first-child .row-with-separator {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }

  .rkt-form-row-group .rkt-form-label {
    font-size: 0.9375rem;
    line-height: 1.4;
    margin-bottom: 0.5rem;
  }

  .repeat-section-add-btn {
    display: none;
  }
}
