:root {
  --title-color: #111111;
  --dashboard-tooltip-bg-color: #ffffff;
  --dashboard-tooltip-text-color: #4a4a4a;
  --dashboard-tooltip-date-text-color: #757575;
}

.dashboard-tooltip {
  position: absolute;
  width: 13rem;
  margin-left: -13rem;
  background: var(--dashboard-tooltip-bg-color);
  box-shadow: 0 6px 24px rgb(141 141 141 / 35%);
  border-radius: 8px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  z-index: $zIndexUserMenu;

  &::after {
    background: white;
    width: 0.75rem;
    height: 0.75rem;
    content: '';
    position: absolute;
    right: -0.375rem;
    top: 0.875rem;
    transform: rotate(45deg);
    border-radius: 1px;
  }

  .date {
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 1.5;
    color: var(--dashboard-tooltip-date-text-color);
    margin-bottom: 0.25rem;
  }

  .item {
    font-size: 0.75rem;
    line-height: 1.41;
    font-weight: 600;
    color: var(--dashboard-tooltip-text-color);
    display: flex;
    position: relative;
    white-space: nowrap;
    align-items: center;
    flex-wrap: wrap;
  }

  .icon {
    width: 0.375rem;
    height: 0.375rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    flex-shrink: 0;
  }

  .icon-value {
    background: var(--legend-value-icon-color);
  }

  .icon-volume {
    background: var(--primary-color);
  }

  .number {
    margin-left: 0.25rem;
  }

  .number-value {
    color: var(--legend-value-icon-color);
  }

  .number-volume {
    color: var(--primary-color);
  }

  .item:not(:last-child) {
    margin-bottom: 0.25rem;
  }
}
