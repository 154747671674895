:root {
  --datepicker-background-color: #ffffff;
  --datepicker-weekday-color: #4a4a4a;
  --custom-day-background-color: #f7f7f7;
  --custom-day-color: #111111;
  --custom-day-disabled-background-color: rgb(247 247 247 / 30%);
  --custom-day-disabled-color: rgb(186 186 186 / 50%);
  --custom-day-muted-background-color: rgb(247 247 247 / 50%);
  --custom-day-muted-color: #bababa;
  --calendar-btn-border-color: #e2e3e4;
  --arrow-btn-color: #4a4a4a;
  --datepicker-brn-transparent-background: transparent;
  --today-background-color: rgb(43 159 173 / 20%);
}

ngb-datepicker {
  &.ngb-dp-body {
    background: var(--datepicker-background-color);
    border-radius: 6px;
    width: 23rem;
  }

  .ngb-dp-header {
    padding-top: 1.25rem;
  }

  .ngb-dp-weekdays {
    border-bottom: none;
    background: var(--datepicker-background-color);
  }

  .ngb-dp-weekday {
    width: 2.5rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4375;
    text-align: center;
    color: var(--datepicker-weekday-color);
    font-style: normal;
    height: 1.4375rem;
    margin-bottom: 0.125rem;
  }

  .ngb-dp-month {
    padding: 0 1.25rem 1.25rem;
    width: 100%;
  }

  .ngb-dp-weekdays,
  .ngb-dp-week {
    margin-bottom: 0.375rem;
    justify-content: space-between;
  }

  .ngb-dp-day {
    width: 2.5rem;
    height: 2.5rem;
  }

  .ngb-dp-month:first-child .ngb-dp-week {
    padding-left: 0;
  }

  .ngb-dp-month:last-child .ngb-dp-week {
    padding-right: 0;
  }

  .custom-day {
    align-items: center;
    justify-content: center;
    padding: 0.3125rem 0.625rem;
    font-weight: 400;
    font-size: 0.9375rem;
    display: flex;
    line-height: 1.364;
    border-radius: 5px;
    height: 100%;
    background: var(--custom-day-background-color);
    color: var(--custom-day-color);
  }

  .custom-day.disabled {
    background: var(--custom-day-disabled-background-color);
    color: var(--custom-day-disabled-color);
  }

  .custom-day:not(.disabled):hover {
    background: var(--primary);
    color: var(--datepicker-background-color);
  }

  .custom-day.selected {
    background: var(--primary);
    border-radius: 5px;
    color: var(--datepicker-background-color);
  }

  .custom-day:not(.disabled).text-muted {
    background: var(--custom-day-muted-background-color);
    color: var(--custom-day-muted-color);
  }

  .custom-day:not(.disabled).text-muted:hover {
    background: var(--primary);
    color: var(--datepicker-background-color);
  }

  .ngb-dp-today .custom-day:not(.selected, .disabled, .text-muted, :hover) {
    background-color: var(--today-background-color);
  }

  .ngb-dp-navigation-select {
    flex: 0 0 15.4375rem;
    justify-content: space-between;
  }

  ngb-datepicker-navigation {
    margin-bottom: 0.375rem;
    padding: 0 1.25rem;
  }

  .ngb-dp-navigation-select .custom-select {
    padding: 0.6875rem 0.1875rem 0.6875rem 0.6875rem;
    width: 7.5rem;
    height: 2.75rem;
    background: var(--datepicker-background-color);
    border: 1px solid var(--calendar-btn-border-color);
    border-radius: 6px;
    flex: none;
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.364;
    color: var(--custom-day-color);
    appearance: none;
    background-image: url('~src/assets/images/chevron-down-calendar.svg');
    background-repeat: no-repeat;
    background-color: var(--datepicker-brn-transparent-background);
    background-size: 1.25rem;
    background-position: right 0.7em top 50%, 0 0;
  }

  .ngb-dp-navigation-select .custom-select:focus {
    outline: none;
    border-color: var(--primary);
    background-image: url('~src/assets/images/chevron-down-calendar-active.svg');
  }

  .ngb-dp-arrow {
    width: 1.5rem;
    align-items: center;
    height: 1.5rem;
    justify-content: center;
  }

  .ngb-dp-arrow:not(.right) {
    margin-right: 0.5rem;
  }

  .ngb-dp-arrow.right {
    justify-content: center;
    margin-left: 0.5rem;

    .ngb-dp-arrow-btn .ngb-dp-navigation-chevron {
      transform: rotate(180deg);
      margin: 0;
    }
  }

  .ngb-dp-arrow-btn {
    margin: 0;
    padding: 0;
    color: var(--arrow-btn-color);
    display: flex;

    .ngb-dp-navigation-chevron {
      background-repeat: no-repeat;
      background-color: var(--datepicker-brn-transparent-background);
      border: none;
      transform: none;
      margin: 0;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url('~src/assets/images/arrow-left.svg');
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  .ngb-dp-arrow-btn:not(:disabled):hover,
  .ngb-dp-arrow-btn:active,
  .ngb-dp-arrow-btn:focus {
    outline: none;

    .ngb-dp-navigation-chevron {
      background-image: url('~src/assets/images/arrow-left-hovered.svg');
    }
  }
}

// Filter Date Picker
.filter-date-selector {
  --calendar-background-color: #ffffff;
  --calendar-border-color: #e2e3e4;
  --calendar-text-color: #111111;
  --calendar-disable-input-background-color: #ebebeb;
  --calendar-weekday-text-color: #4a4a4a;
  --calendar-range-faded-background-color: #d9eff1;

  position: absolute;
  background: var(--calendar-background-color);
  border: 1px solid var(--calendar-border-color);
  border-radius: 6px;
  padding: 0.75rem 1rem 0;
  top: 0;

  &:not(.right-side) {
    margin-right: 1.25rem;
    right: 100%;
  }

  &.right-side {
    left: 100%;
    margin-left: 1.25rem;
  }

  .title {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.428;
    display: flex;
    align-items: center;
    color: var(--calendar-text-color);
    margin-bottom: 0.75rem;
  }

  .selected-range {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .from-to {
    .date-label {
      font-weight: 400;
      font-size: 0.8125rem;
      line-height: 1.384;
      color: var(--calendar-text-color);
      margin-bottom: 0.25rem;
    }

    .date-field {
      position: relative;
    }

    .date-input {
      padding: 0.625rem 3rem 0.625rem 1rem;
      height: 2.75rem;
      background: var(--calendar-background-color);
      border: 1px solid var(--calendar-border-color);
      border-radius: 4px;
      font-size: 0.9375rem;
      line-height: 1.4;
      color: var(--calendar-text-color);
      font-weight: 400;
      width: 10rem;

      &:disabled {
        background: var(--calendar-disable-input-background-color);
        border-color: var(--calendar-disable-input-background-color);
      }

      &:focus,
      &.active {
        border-color: var(--primary);
        outline: none;
      }
    }

    .calendar-icon {
      position: absolute;
      font-size: 1.5rem;
      right: 0.625rem;
      top: 0.625rem;
      color: var(--calendar-text-color);
    }

    .clear-btn {
      position: absolute;
      font-size: 1.5rem;
      right: 0.625rem;
      top: 0.625rem;
      color: var(--calendar-text-color);

      &:hover {
        background: var(--action-button-hover-bg-color);
        border-radius: 50%;
        outline: 2px solid var(--action-button-hover-bg-color);
        color: var(--whats-new-close-btn-hover-color);
      }
    }
  }

  ngb-datepicker {
    border: none;
    border-radius: 0;
    display: block;
    width: 19.75rem;
    margin: 0 auto;

    .ngb-dp-header {
      padding-top: 0;
    }

    ngb-datepicker-navigation {
      padding: 0;
    }

    .ngb-dp-month {
      padding: 0;
      overflow: hidden;
    }

    .ngb-dp-weekday {
      color: var(--calendar-weekday-text-color) !important;
      height: 1.25rem;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.428;
    }

    .ngb-dp-month .ngb-dp-week {
      gap: 0.375rem;
    }

    .ngb-dp-month:last-child .ngb-dp-week {
      margin-bottom: 0.5rem;
    }
  }

  .custom-day {
    position: relative;
  }

  .custom-day.today:not(.range) {
    background-color: var(--today-background-color);
  }

  .custom-day.range,
  .custom-day:hover {
    background-color: var(--primary) !important;
    color: var(--calendar-background-color) !important;
  }

  .custom-day.range:not(.end):after {
    content: '';
    position: absolute;
    height: 2.5rem;
    width: 0.375rem;
    left: 100%;
    background: var(--calendar-range-faded-background-color);
  }

  .custom-day.start {
    border-radius: 6px 0 0 6px;
  }

  .custom-day.end {
    border-radius: 0 6px 6px 0;
  }

  .custom-day.start.end {
    border-radius: 6px;
  }

  .custom-day.faded {
    border-radius: 0;
    color: var(--calendar-text-color) !important;
    background-color: var(--calendar-range-faded-background-color) !important;
  }

  .calendar-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1.5rem;
  }
}
