:root {
  --attachment-action-button-hover-bg-color: #e2e3e4;
  --attachment-action-button-edit-color: #2b9fad;
  --attachment-action-button-delete-color: #ff6e6e;
}

.attachment-upload-selected-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.uploaded-attachment-item {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(43 159 173 / 10%);
  border: 1px solid #eff0f1;
  border-radius: 6px;
  font-weight: 400;
  font-size: $font-size-sm;
  line-height: 1.25rem;
  color: $primary-black;

  .attachment-status {
    font-size: 1.75rem;
    color: $ux-cornflower-blue;
  }

  .name {
    flex: 1;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.attachment-action-button {
  font-size: 1.25rem;
  padding: 0.125rem;
}

.attachment-action-button:hover {
  border-radius: 50%;
  background: var(--attachment-action-button-hover-bg-color);
  outline: 2px solid var(--attachment-action-button-hover-bg-color);
}

.attachment-action-button-edit {
  color: var(--attachment-action-button-edit-color);
}

.attachment-action-button-delete {
  color: var(--attachment-action-button-delete-color);
}

.attachments-scroll-wrapper {
  min-height: 10rem;
}

.attachments-drag-drop-hovering {
  position: relative;
  user-select: none;

  .attachments-hover-overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 2px dashed var(--attachment-action-button-edit-color);
    background-color: rgb(43 159 173 / 20%);
    transition: all 0.2s ease;
    pointer-events: none;
    z-index: 999999;
  }
}
