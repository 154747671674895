:root {
  --back-button-color: #111111;
  --back-button-hover-color: var(--primary);
  --breadcrumb-item-color: #757575;
  --breadcrumb-item-last-color: #111111;
  --breadcrumb-separator-color: #bababa;
  --action-button-hover-bg-color: #e2e3e4;
  --action-button-delete-color: #ff6e6e;
  --action-button-suspend-color: #ffb257;
  --action-button-unsuspend-color: #43ca21;
  --action-button-edit-color: #2b9fad;
  --last-updated-color: #757575;
}

.details-page-header-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;

  .page-header {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .details-page-back-title {
    display: flex;
    align-items: center;
  }

  .details-page-back-btn {
    margin-right: 0.5rem;
    font-size: 1.5rem;
    flex-shrink: 0;
    color: var(--back-button-color);

    &:hover {
      color: var(--back-button-hover-color);
    }
  }

  .details-page-title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.375;
    height: 2.0625rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .status-tag,
  .stage-tag {
    margin-left: 0.75rem;
    flex-shrink: 0;
  }

  .page-header-controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
  }

  .page-header-controls.no-actions {
    justify-content: flex-end;
  }

  .entity-actions {
    display: flex;
    gap: 1.25rem;
    justify-content: flex-end;
  }

  .action-button {
    padding: 0.25rem;
    font-size: 1.5rem;

    &:not(:disabled):hover {
      background: var(--action-button-hover-bg-color);
      border-radius: 50%;
      box-shadow: 0 0 0 2px var(--action-button-hover-bg-color);
    }
  }

  .action-button-delete,
  .action-button-cancel {
    color: var(--action-button-delete-color);
  }

  .action-button-unsuspend,
  .action-button-unpause {
    color: var(--action-button-unsuspend-color);
  }

  .action-button-suspend,
  .action-button-pause {
    color: var(--action-button-suspend-color);
  }

  .action-button-reverse,
  .action-button-edit,
  .action-button-resend {
    color: var(--action-button-edit-color);
  }

  .action-button:disabled {
    opacity: 0.5;
  }

  .last-updated {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.41;
    text-align: right;
    color: var(--last-updated-color);
  }
}

.entity-actions.dark-bg {
  .action-button-reverse,
  .action-button-edit,
  .action-button-resend {
    color: var(--white-color);

    &:not(:disabled):hover {
      color: var(--primary-color);
    }
  }
}

.details-page-header-wrapper.dark-bg {
  --back-button-color: var(--white-color);

  .details-page-title {
    color: var(--white-color);
  }

  .page-breadcrumbs {
    --breadcrumb-item-last-color: var(--white-color);
    --breadcrumb-item-color: rgb(255 255 255 / 50%);
  }
}
