:root {
  --auth-title-color: #111111;
  --auth-error-color: var(--error-color);
  --tenant-label-color: #111111;
  --tenant-value-color: #111111;
  --auth-back-btn-color: #bababa;
  --auth-back-btn-hover-color: var(--primary);
}

.auth-title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.375;
  color: var(--auth-title-color);
  margin-bottom: 1.75rem;
  display: flex;
}

.auth-title-page-back-btn {
  margin-right: 1rem;
  display: inline-flex;
  align-self: center;
}

.auth-title-page-back-btn-icon {
  font-size: 1.5rem;
  color: var(--auth-back-btn-color);
}

.auth-title-page-back-btn-icon:hover {
  color: var(--auth-back-btn-hover-color);
}

.auth-error {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.384;
  color: var(--auth-error-color);
  margin-bottom: 1.25rem;
}

.auth-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;

  .rkt-button-primary {
    justify-content: center;
  }
}

.tenant {
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
}

.tenant-label {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.384;
  color: var(--tenant-label-color);
  margin-bottom: 1rem;
}

.tenant-value {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.4;
  color: var(--tenant-value-color);
}
