.tab-nav-items {
  display: flex;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid var(--default-border-color);
}

.tab-nav-items:not(.no-horizontal-offset) {
  padding-left: 2rem;
}

.tab-nav-item {
  display: flex;
  align-items: flex-start;
  margin-right: 1.75rem;
}

.tab-nav-link {
  font-size: 1rem;
  line-height: 1.375;
  color: var(--tab-link-color);
  font-weight: 400;
  position: relative;
  padding-bottom: 0.5rem;
  cursor: pointer;

  &:after {
    content: '';
    transition: all 0.2s linear;
    border-bottom: 0.25rem solid var(--tab-link-border-color);
    left: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
    border-radius: 10px 10px 0 0;
  }
}

.highlighted::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: var(--error-color);
  top: 0.5rem;
  right: -0.75rem;
}

.tab-nav-link:hover {
  text-decoration: none;
}

.tab-nav-link.active {
  --tab-link-color: var(--text-primary-color);
  --tab-link-border-color: var(--primary-color);

  font-weight: 700;
  cursor: default;
}

.tab-nav-link:not(.active):hover {
  --tab-link-border-color: var(--primary-color);
}
