// ROCKET UI
@import 'rocket-ui/auth-page';
@import 'rocket-ui/details-page-header';
@import 'rocket-ui/details-page';
@import 'rocket-ui/list-page-header';
@import 'rocket-ui/table';
@import 'rocket-ui/toast';
@import 'rocket-ui/calendar';
@import 'rocket-ui/filter-menu';
@import 'rocket-ui/forms';

:root {
  --page-header-title-color: #111111;
  --error-color: #ff6e6e;
  --attention-modal-title-color: #111111;
  --attention-modal-message-color: #757575;
  --attention-modal-bg-color: #ffffff;
  --form-field-placeholder-color: #bababa;
  --table-disabled-row-text-color: #bababa;
  --close-button-color: #757575;
  --close-button-hover-bg-color: #e2e3e4;
  --modal-title-color: #111111;
  --text-primary-color: #111111;
  --text-secondary-color: #4a4a4a;
  --text-tertiary-color: #757575;
  --primary-color: #2b9fad;
  --success-color: #43ca21;
  --loading-placeholder-primary: #f5f5f5;
  --loading-placeholder-secondary: #e9e9e9;
  --loading-placeholder-dark-primary: #e6e6e6;
  --loading-placeholder-dark-secondary: #eeeeee;
  --btn-link-hover-color: #1f7984;
  --default-separator-color: #e2e3e4;
  --default-border-color: #e2e3e4;
  --value-empty-color: #bababa;
  --tab-link-color: #4a4a4a;
  --tab-link-border-color: transparent;
  --container-accent-bg-color: #f9f9f9;
  --transparent: transparent;
  --secondary-color: #175770;
  --disabled-field-color: #ebebeb;

  // statuses
  --status-text-color: #111111;
  --status-new-text-color: #2b9fad;
  --status-new-bg-color: #ffffff;
  --status-success-bg-color: #b4f89d;
  --status-warning-bg-color: #ffd187;
  --status-error-bg-color: #ff9898;
  --status-empty-bg-color: transparent;

  // verifications
  --verification-approved: #288112;
  --verification-processing: #de7800;
  --verification-restricted: #bb6500;
  --verification-declined: #d32121;
  --verification-review: var(--secondary-color);
  --verification-unverified: #757575;

  // variables
  --modal-content-width: 41.25rem;
}

.page-header {
  margin-top: 0.375rem;
  margin-bottom: 1.25rem;

  .page-header-title {
    color: var(--page-header-title-color);
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.375;
  }
}

.page-header-controls {
  margin-top: 0.375rem;
  margin-bottom: 1.25rem;
}

.stage-tag {
  white-space: nowrap;
  padding: 0.375rem 0.75rem;
  border-radius: 1.875rem;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 1.364;
  text-align: center;
  color: var(--text-primary-color);
  background: rgb(161 202 210);
  border: 1px solid rgb(161 202 210);
}

.status-tag {
  white-space: nowrap;
  padding: 0.375rem 0.75rem;
  border-radius: 1.875rem;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 1.364;
  text-align: center;
  color: var(--status-text-color) !important;
  background: var(--status-warning-bg-color) !important;
  border: 1px solid var(--status-warning-bg-color) !important;
}

.status-tag.no-value {
  background: var(--status-empty-bg-color) !important;
  border-color: var(--status-empty-bg-color) !important;
}

.status-tag.status-tag-placeholder {
  background: var(--status-empty-bg-color) !important;
  border-color: var(--status-empty-bg-color) !important;
  padding: 0;
}

.status-tag.status-tag-new {
  color: var(--status-new-text-color) !important;
  background: var(--status-new-bg-color) !important;
  border-color: var(--status-new-text-color) !important;
  text-transform: uppercase;
  display: inline-flex;
}

.status-tag.status-tag-active,
.status-tag.status-tag-processing,
.status-tag.status-tag-approved,
.status-tag.status-tag-settled,
.status-tag.status-tag-cleared,
.status-tag.status-tag-completed,
.status-tag.status-tag-finished,
.status-tag.status-tag-complete,
.status-tag.status-tag-resolved {
  color: var(--status-text-color) !important;
  background: var(--status-success-bg-color) !important;
  border-color: var(--status-success-bg-color) !important;
}

.status-tag.status-tag-suspended,
.status-tag.status-tag-pending {
  color: var(--status-text-color) !important;
  background: var(--status-warning-bg-color) !important;
  border-color: var(--status-warning-bg-color) !important;
}

.status-tag.status-tag-error,
.status-tag.status-tag-denied,
.status-tag.status-tag-declined,
.status-tag.status-tag-canceled,
.status-tag.status-tag-cancelled,
.status-tag.status-tag-deleted,
.status-tag.status-tag-expired,
.status-tag.status-tag-failed {
  color: var(--status-text-color) !important;
  background: var(--status-error-bg-color) !important;
  border-color: var(--status-error-bg-color) !important;
}

.status-tag-disbursing,
.status-tag-funding,
.status-tag-cancelling {
  color: var(--text-primary-color) !important;
  background: rgb(241 247 248) !important;
  border: 1px solid rgb(161 202 210) !important;
}

.rkt-form-label .asterisk {
  color: var(--error-color);
}

.auth-form {
  &.auth-form-submit-error .rkt-form-input {
    border-color: var(--error-color);
  }

  .rkt-form {
    .rkt-form-row-group:not(:first-child) {
      margin-top: 0.75rem;
    }

    .auth-reset-password-tenant-row {
      margin-top: 1.625rem;
      margin-bottom: 1.625rem;
    }

    .auth-tenant-row {
      margin-top: 3.125rem;
      margin-bottom: 1.875rem;
    }
  }
}

.modal-content-attention {
  padding: 2.5rem 1.75rem;

  .modal-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5;
    text-align: center;
    color: var(--attention-modal-title-color);
    margin-bottom: 0.75rem;
  }

  .modal-message {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.4;
    text-align: center;
    color: var(--attention-modal-message-color);
    overflow-wrap: break-word;
    width: 100%;
  }

  .modal-buttons {
    margin-top: 1.75rem;
    display: flex;
    gap: 1.5rem;
  }

  .modal-icon {
    background-repeat: no-repeat;
    width: 8.25rem;
    height: 8.25rem;
    margin-bottom: 0.75rem;
  }

  .modal-icon-error {
    background-image: url('~src/assets/images/speech-bubble-alert.svg');
  }

  .modal-icon-success {
    background-image: url('~src/assets/images/speech-bubble-success.svg');
  }

  .modal-icon-warning {
    background-image: url('~src/assets/images/speech-bubble-warning.svg');
  }

  .modal-icon-scheduled {
    background-image: url('~src/assets/images/speech-bubble-scheduled.svg');
  }

  .modal-icon-suspend {
    background-image: url('~src/assets/images/speech-bubble-suspend.svg');
  }

  .modal-icon-resume {
    background-image: url('~src/assets/images/speech-bubble-resume.svg');
  }
}

.modal-overlay {
  .modal-body {
    position: relative;
  }

  .modal-content {
    margin: 0 auto;
    background: var(--attention-modal-bg-color);
    border-radius: 8px;
    box-shadow: none;
    width: var(--modal-content-width);
  }
}

.modal-overlay.auth-error-modal,
.modal-overlay.success-modal,
.modal-overlay.confirm-modal {
  --modal-content-width: 23.625rem;

  .modal-content {
    margin: 0 auto;
    background: var(--attention-modal-bg-color);
    border-radius: 8px;
    box-shadow: none;
  }
}

.modal-overlay.confirm-modal.note-delete-confirm {
  .modal-content {
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 50%);
  }
}

.modal-overlay.auth-document-modal {
  .modal-body {
    padding: 1.75rem 2.25rem;
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .modal-content {
    width: calc(100vw - 3.75rem);
    margin: 0 auto;
    background: var(--attention-modal-bg-color);
    border-radius: 8px;
    box-shadow: none;
    height: calc(100vh - 3.75rem);
  }
}

.modal-overlay.report-document-modal {
  .modal-body {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .modal-content {
    width: calc(100vw - 30rem);
    height: calc(100vh - 10rem);
  }
}

.natural-language-search-modal .rkt-table-search-container {
  --input-border-color: #bababa;
  --input-border-focus-color: #2b9fad;

  .rkt-table-search-input {
    border-color: var(--input-border-color);
  }

  .rkt-table-search-input:focus {
    border-color: var(--input-border-focus-color);
  }
}

.modal-overlay.natural-language-search-modal {
  --modal-content-width: 66.5rem;
}

.modal-overlay.natural-language-search-modal,
.modal-overlay.entity-form-modal {
  --close-button-color: #757575;
  --close-button-hover-bg-color: #e2e3e4;
  --modal-title-color: #111111;
  --buttons-container-border-color: #e2e3e4;

  .content {
    overflow-y: scroll;
    position: relative;
    backface-visibility: hidden;
    transform: translateZ(0);
  }

  .modal-back-btn-icon .rkt-icon {
    width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
  }

  .modal-back-btn-icon:hover {
    color: var(--back-button-hover-color);
  }

  .close-button {
    position: absolute;
    right: 2.25rem;
    top: 1.875rem;
    font-size: 1.5rem;
    color: var(--close-button-color);

    &:hover {
      background: var(--close-button-hover-bg-color);
      border-radius: 50%;
      outline: 2px solid var(--close-button-hover-bg-color);
    }
  }

  .title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5;
    color: var(--modal-title-color);
    margin-bottom: 1.25rem;
  }

  .buttons {
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
  }

  .row-with-separator {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--buttons-container-border-color);
  }

  .modal-content {
    background: var(--attention-modal-bg-color);
    border-radius: 8px;
    box-shadow: none;
    margin: 0 auto;
  }

  .modal-body {
    padding: 1.75rem 2.25rem;
    position: relative;
  }
}

.rkt-form .entity-description-field .rkt-form-textarea {
  height: 5.5rem;
}

.modal-overlay.transaction-limits-edit-modal {
  --modal-content-width: 35.375rem;
}

@keyframes notes-modal-slide {
  0% {
    transform: translateX(29.5rem);
  }

  100% {
    transform: translateX(0);
  }
}

.modal-overlay.notes-list {
  --modal-content-width: 29.5rem;

  animation-duration: 0.5s;
  animation-name: notes-modal-slide;

  .modal-dialog {
    margin: 0;
    height: 100vh;
    position: relative;
    align-items: stretch;
    justify-content: flex-end;
  }

  .modal-content {
    margin: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-body {
    height: 100%;
  }
}

.modal-overlay.linx-request-edit-modal {
  --modal-content-width: 27.5rem;

  ngb-datepicker {
    background: white;
    margin-top: 3.125rem !important;
  }
}

.modal-overlay.linx-request-customer-modal {
  --modal-content-width: 42rem;
}

.hidden,
[hidden] {
  display: none !important;
}

.rkt-form .rkt-form-row .rkt-form-control.sec-selector-field {
  max-width: calc((100%) / 3 * 2 - 0.625rem);
}

.rkt-form .rkt-form-row .rkt-form-control.ach-details-field {
  max-width: calc((100%) / 3 - 1.25rem);
  margin-bottom: 1.25rem;
}

.rkt-form .rkt-form-select.ng-select .ng-placeholder {
  color: var(--form-field-placeholder-color);
}

.rkt-form .rkt-form-select.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.rkt-form .push-notification-description .rkt-form-textarea {
  height: 7.625rem;
}

.move-money-review-modal {
  --modal-content-width: 56.125rem;

  .modal-content {
    padding: 1.75rem 2.25rem;
  }
}

.move-money-success-modal {
  --modal-content-width: 35rem;

  .modal-content {
    padding: 1.75rem 2.25rem;
  }

  .modal-title {
    margin-bottom: 0.375rem;
  }

  .modal-buttons {
    margin-top: 1.25rem;
  }

  .transaction-id {
    margin-bottom: 1.25rem;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.4;
    text-align: center;
    color: var(--text-secondary-color);
  }

  .modal-content-attention {
    padding: 0;
  }

  .modal-message {
    width: 100%;
  }

  .transaction-info {
    padding: 0.75rem 1rem;
    background: #f9f9f9;
    border-radius: 9px;

    .row {
      display: flex;
      gap: 0.75rem;
    }

    .row:not(:last-child, .row-no-border) {
      margin-bottom: 0.75rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid #e2e3e4;
    }

    .row-no-border {
      margin-bottom: 0.75rem;
    }

    .label {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.642;
      color: var(--text-secondary-color);
      flex: 0 0 7.5rem;
      text-align: left;
    }

    .value {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.4375;
      color: var(--text-primary-color);
      flex: 1;
      min-width: 0;
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      word-wrap: break-word;

      .label-value {
        font-weight: 400;
      }
    }
  }
}

.transaction-batch-details-description .details-item .details-item-value {
  font-weight: 400;
}

.separator-line,
.separator-row {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #e2e3e4;
}

.modal-overlay.edit-transaction-modal {
  --modal-content-width: 68.875rem;

  .close-button {
    position: absolute;
    right: 2.25rem;
    top: 1.75rem;
    font-size: 1.5rem;
    color: var(--close-button-color);

    &:hover {
      background: var(--close-button-hover-bg-color);
      border-radius: 50%;
      outline: 2px solid var(--close-button-hover-bg-color);
    }
  }

  .title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5;
    color: var(--modal-title-color);
    padding: 1.75rem 2.25rem 0;
  }

  .entity-details-container {
    padding: 1.25rem 2.25rem;
  }

  .registry-form-preview {
    margin-right: 0;
  }
}

.modal-overlay.transaction-reversal-reason-modal {
  --modal-content-width: 27.5rem;
}

.modal-overlay.identity-verification-modal {
  font-size: 0.9375rem;

  .modal-content {
    width: 27.1875rem;
  }

  .modal-sub-title {
    text-align: center;
    color: var(--text-tertiary-color);
  }

  .modal-message {
    font-weight: 600;
    color: var(--error-color);
    margin-top: 1.75rem;
    text-align: left;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

.modal-overlay.primary-address-selection-modal {
  z-index: 1001;

  .modal-body {
    padding: 1.75rem 2.25rem;
    position: relative;
  }
}

.modal-overlay.primary-address-selection-modal + .modal-backdrop.show {
  z-index: 1000;
}

.modal-overlay.linx-request-modal,
.entity-form-modal {
  ngb-datepicker {
    background: white;
    margin-top: 3.125rem !important;
    z-index: 1;
  }

  .rkt-form-datepicker-field ngb-datepicker {
    margin-top: 0.25rem !important;
  }
}

.modal-overlay.resend-activation-email-modal {
  --modal-content-width: 27.5rem;
}

.rkt-table .header-cell-sortable .column-sort-btn {
  flex-shrink: 0;
}

.rkt-table .datatable-body-row-disabled {
  cursor: not-allowed;

  &:hover .body-cell-inner {
    background: transparent;
    cursor: not-allowed;
  }

  .body-cell {
    color: var(--table-disabled-row-text-color);
    cursor: not-allowed;
  }

  .body-cell-value.status-tag {
    opacity: 0.4;
  }
}

.latest-note .note-item-wrapper {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.entity-review-modal {
  --modal-content-width: 66rem;
}

.documents-modal {
  --modal-content-width: 50rem;
}

.financial-account-activity-table .rkt-table .datatable-body-row-disabled {
  .body-cell {
    color: var(--text-primary-color);
  }

  .combined-value-id {
    color: var(--table-disabled-row-text-color);
  }
}

.loading-placeholder {
  background: var(--loading-placeholder-primary);
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.loading-placeholder::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      to right,
      var(--loading-placeholder-primary) 0%,
      var(--loading-placeholder-secondary) 50%,
      var(--loading-placeholder-primary) 100%
    )
    0 0 / 90% 100% no-repeat var(--loading-placeholder-primary);
  transform: translateX(-100%);
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-name: placeholder-loading-animation;
}

.loading-placeholder.dark-bg {
  background: var(--loading-placeholder-dark-primary);

  &::before {
    background: linear-gradient(
        to right,
        var(--loading-placeholder-dark-primary) 0%,
        var(--loading-placeholder-dark-secondary) 50%,
        var(--loading-placeholder-dark-primary) 100%
      )
      0 0 / 90% 100% no-repeat var(--loading-placeholder-dark-primary);
  }
}

@keyframes placeholder-loading-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.form-title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4375;
  color: var(--text-primary-color);
  margin-bottom: 0.5rem;
  margin-top: 1.25rem;
}

.single-row-radio-group .rkt-form-radio-group-field {
  padding: 0.25rem 0;
}

.multi-row-radio-group .rkt-form-radio-group-field {
  flex-direction: column;
}

.rkt-form-row {
  .rkt-form-control {
    &.masked-social-id-row-field {
      .rkt-form-label::before {
        width: 1rem;
        height: 1rem;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj4KICAgIDxwYXRoIGZpbGw9IiMwMDAiIGQ9Ik04IC42NjdjLTIuMjE2IDAtNCAxLjc4NC00IDR2LjY2NmMtLjczMyAwLTEuMzMzLjYtMS4zMzMgMS4zMzR2Ni42NjZjMCAuNzM0LjYgMS4zMzQgMS4zMzMgMS4zMzRoOGMuNzMzIDAgMS4zMzMtLjYgMS4zMzMtMS4zMzRWNi42NjdjMC0uNzM0LS42LTEuMzM0LTEuMzMzLTEuMzM0di0uNjY2YzAtMi4yMTYtMS43ODQtNC00LTRaTTggMmMxLjUxNyAwIDIuNjY3IDEuMTUgMi42NjcgMi42Njd2LjY2Nkg1LjMzM3YtLjY2NkM1LjMzMyAzLjE0OSA2LjQ4MyAyIDggMlptMCA2LjY2N2MuNzMzIDAgMS4zMzMuNiAxLjMzMyAxLjMzM3MtLjYgMS4zMzMtMS4zMzMgMS4zMzMtMS4zMzMtLjYtMS4zMzMtMS4zMzMuNi0xLjMzMyAxLjMzMy0xLjMzM1oiLz4KICA8L2c+CiAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImEiPgogICAgICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDE2djE2SDB6Ii8+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KPC9zdmc+Cg==');
        background-repeat: no-repeat;
        background-position: center;
        content: '';
        margin-right: 0.25rem;
      }

      .rkt-form-input {
        padding: 0;
        background: transparent;
        border: none;
        font-weight: bold;
      }
    }

    &.percentage-owned-row-field {
      .rkt-form-label::after {
        content: '(Must be a number below 100)';
        color: var(--text-tertiary-color);
        margin-left: 0.25rem;
      }

      .rkt-form-input-field::after {
        content: '%';
        position: absolute;
        right: 1.25rem;
        font-size: 0.9375rem;
        top: 0.75rem;
        user-select: none;
        pointer-events: none;
      }
    }
  }
}

.rkt-form-field-group.address-field-group .rkt-form-field {
  .rkt-form-field-link-container {
    bottom: auto !important;
    top: 0;
  }

  .rkt-form-field-link {
    color: var(--btn-danger-color);

    &:hover {
      color: var(--btn-danger-hover-color);
    }
  }
}

.linx-request-review-modal {
  --modal-content-width: 90rem;

  .modal-content {
    padding: 1.75rem 2.25rem;
  }
}

.modal-overlay.statement-email-modal {
  --modal-content-width: 27.5rem;

  .message {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.4;
    color: var(--text-secondary-color);
  }

  .accent {
    font-weight: 700;
  }

  .email {
    word-break: break-word;
  }
}

.rkt-form-control.dispute-create-description {
  color: var(--text-tertiary-color);
  font-size: 0.8125rem;
}

.rkt-form .rkt-form-row .rkt-form-control.report-dropdown {
  flex: 2;
}

.rkt-form-control.dispute-create-amount,
.rkt-form-control.alert-create-amount {
  .rkt-form-input {
    padding-left: 2.75rem;
  }

  .rkt-form-input-field::before {
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMCAyMCI+CiAgPHBhdGggZmlsbD0iIzc1NzU3NSIgZD0iTTEyLjU3IDEwLjMwNmMtLjQ5LS4yOTMtMS4xNzMtLjU2LTIuMDQ2LS44MDQtLjg3My0uMjQzLTEuNS0uNTE0LTEuODgxLS44MTItLjM4Mi0uMjk3LS41NzItLjY5OC0uNTcyLTEuMiAwLS41NC4xOC0uOTYzLjU0Mi0xLjI2Ni4zNi0uMzAyLjg4Mi0uNDUzIDEuNTYzLS40NTMuNjUzIDAgMS4xNzIuMjA0IDEuNTU1LjYxMy4zODQuNDEuNTc2Ljk2MS41NzYgMS42NTV2LjA1MmgxLjU4MVY4LjA0YzAtMS4wMDctLjI2Ny0xLjgtLjgwMy0yLjM4LS41MzYtLjU4LTEuMjg0LS45MjYtMi4yNDctMS4wMzloLS4wMDVWM0g5LjV2MS42MjNjLS45MjguMTA1LTEuNjYzLjQwNy0yLjE5OC45MTItLjU0Ny41MTYtLjgyMSAxLjE3Ni0uODIxIDEuOTgyIDAgLjc5LjI3OSAxLjQ0My44MzggMS45NTcuNTU4LjUxMyAxLjQ0OC45MyAyLjY2OCAxLjI0OS44NzYuMjUgMS40OTkuNTMgMS44NjkuODQuMzY5LjMxLjU1NC42OS41NTQgMS4xNDIgMCAuNTM2LS4yMTUuOTU5LS42NDUgMS4yNjgtLjQzLjMxLTEuMDIuNDY0LTEuNzcuNDY0LS43NjggMC0xLjM2MS0uMTg0LTEuNzc5LS41NTMtLjQxOC0uMzY4LS42MjctLjg5NC0uNjI3LTEuNTc3SDZjMCAuOTczLjMwMiAxLjc0My45MDYgMi4zMS41ODkuNTUzIDEuNC44NzIgMi40MjcuOTY2VjE3aDEuMzM0di0xLjQwNmwuMDE2LS4wMDFjMS4wNDgtLjA5NCAxLjg2NC0uMzkzIDIuNDQ1LS45LjU4LS41MDUuODcyLTEuMTczLjg3Mi0yLjAwMyAwLS41Mi0uMTE3LS45NzUtLjM0OC0xLjM2NC0uMjMyLS4zODctLjU5My0uNzI4LTEuMDgzLTEuMDJaIi8+Cjwvc3ZnPgo=');
    position: absolute;
    left: 1.25rem;
    top: 0.75rem;
    pointer-events: none;
  }
}

.address-form {
  position: relative;

  .repeat-section-item-wrapper {
    position: relative;
    padding-top: 1.25rem;
    margin-top: 1.25rem;
    border-top: 1px solid var(--default-separator-color);

    &:first-child .repeat-section-remove-btn {
      top: 0;
    }
  }

  .repeat-section-item-wrapper:first-child .repeat-section-header::after {
    content: ' - Primary';
  }

  .repeat-section-header {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4375rem;
  }

  .repeat-section-subheader {
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.5rem 0;
    color: var(--text-tertiary-color);
  }

  .rkt-form-repeat-section-item .rkt-form-row {
    flex-direction: column;
    gap: 0;
  }

  .rkt-form-row .rkt-form-row {
    flex-direction: row;
    gap: 1.25rem;
  }

  .rkt-form-repeat-section-item .repeat-section-remove-btn {
    position: absolute;
    margin: 0;
    right: 0;
    top: 1.25rem;
    color: var(--btn-danger-color);

    &:hover {
      color: var(--btn-danger-hover-color);
    }

    .rkt-icon {
      display: none;
    }
  }

  .repeat-section-add-btn {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.428;
    color: var(--primary-color);

    &:hover {
      color: var(--btn-link-hover-color);
    }

    .rkt-icon {
      font-size: 1.25rem;
    }
  }

  .repeat-section-button-container {
    gap: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.row-content .address-form .repeat-section-item-wrapper:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}

.dispute-questions-form {
  .rkt-form-repeat-section-item {
    flex-direction: column;
    position: relative;
    counter-increment: questionCounter;

    &:not(:first-child) {
      padding-top: 1.25rem;
      margin-top: 1.25rem;
      border-top: 1px solid var(--default-separator-color);
    }
  }

  .rkt-form .rkt-form-row {
    flex-direction: column;
    gap: 0;
  }

  .rkt-form-repeat-section-item .repeat-section-remove-btn {
    position: absolute;
    margin: 0;
    right: 0;
    top: 1.25rem;
    color: var(--btn-danger-color);

    &:hover {
      color: var(--btn-danger-hover-color);
    }

    .rkt-icon {
      display: none;
    }
  }

  .rkt-form-row-group:first-child .row-with-separator {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }

  .rkt-form-row-group .rkt-form-label {
    font-size: 0.9375rem;
    line-height: 1.4;
    margin-bottom: 0.5rem;
  }

  .repeat-section-add-btn {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.428;
    color: var(--primary-color);

    &:hover {
      color: var(--btn-link-hover-color);
    }

    .rkt-icon {
      font-size: 1.25rem;
    }
  }
}

.dispute-optional-questions-form {
  .rkt-form-row-group .rkt-form-label {
    font-size: 0.8125rem;
    line-height: 1.384;
    margin-bottom: 0.25rem;
  }

  .rkt-form-label label::after {
    content: counter(questionCounter);
    margin-left: 0.25rem;
  }

  .rkt-form-repeat-section-item:first-child .repeat-section-remove-btn {
    top: 0;
  }
}

.restriction-form-modal.entity-form-modal .rkt-form-datepicker-field ngb-datepicker {
  margin-top: 0 !important;
  top: 0 !important;
}

.rkt-form-row .rkt-form-control.yes-no-field {
  max-width: 9.375rem;
}

.move-money-scheduler-form {
  ngb-datepicker {
    z-index: 2 !important;
    transform: none !important;
  }
}

.modal-overlay.schedule-recurrence-modal {
  --modal-content-width: 27.5rem;

  .full-height-row-radio-group .rkt-form-radio-group-field {
    height: 2.8125rem;
    align-items: center;
  }

  .no-label-field .rkt-form-label {
    display: none;
  }

  .empty-label-field .rkt-form-label {
    white-space: pre;

    &::after {
      content: ' ';
    }
  }

  .recurrence-ends-row {
    margin-top: 1.25rem;
  }

  .rkt-form-control.interval-field,
  .rkt-form-control.recurrence-ends-field {
    max-width: 5.5rem;
  }

  .occurrence-counter-field {
    .rkt-form-field {
      flex-direction: row;
      align-items: center;
      gap: 1.25rem;
      font-size: 0.9375rem;
      color: var(--text-secondary-color);
    }

    .rkt-form-input-field {
      max-width: 9.0625rem;
    }
  }

  .rkt-form-field-invalid-feedback {
    position: absolute;
    top: 100%;
  }
}

.schedule-recurrence-modal .rkt-form-datepicker-field ngb-datepicker {
  transform: none !important;
}

.escrow-account-details {
  margin-bottom: 1.25rem;

  .details-row {
    grid-template-columns: repeat(4, 1fr);
  }

  .details-item-label {
    font-size: 0.875rem !important;
  }

  .details-item-value {
    font-size: 1.125rem !important;
    font-weight: 700 !important;
  }
}

.sidebar {
  .rkt-table-search-container {
    margin: 0 0.75rem;
  }

  .rkt-table-search-input {
    width: 2.5rem;
    border-color: var(--btn-link-hover-color);
  }
}

.business-account-list-modal {
  --modal-content-width: 66.5rem;

  .modal-body {
    padding: 1.75rem 2.25rem;
  }

  .modal-title {
    color: var(--text-primary-color);
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }

  .modal-buttons {
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
  }

  .list-page-controls {
    --table-filter-btn-border-color: #e2e3e4;

    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }

  .rkt-table-search {
    --input-border-color: #e2e3e4;
  }
}

.modal-overlay.view-attachment-modal {
  background: var(--text-primary-color);

  --modal-content-width: 100%;

  .modal-dialog {
    margin: 0;
    height: 100vh;
    position: relative;
    align-items: stretch;
    justify-content: flex-end;
  }

  .modal-body {
    height: 100%;
    background: var(--text-primary-color);
  }
}

.modal-overlay.account-verification-history-modal {
  --modal-content-width: 37.5rem;

  .modal-body {
    padding: 1.75rem 2.25rem;
  }

  .modal-content {
    max-height: calc(100vh - 3.75rem);
  }

  .title {
    color: var(--text-primary-color);
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }

  .history-items {
    overflow-y: auto;
    flex: 1;
    overflow-wrap: anywhere;
    max-height: calc(100vh - 14.75rem);
  }

  .buttons {
    margin-top: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.inline-status {
  font-size: 0.625rem;
  line-height: 1.5;
  font-weight: 700;
  background: var(--white);
  padding: 0.125rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.125rem;
  color: var(--verification-color);
}

.inline-status-approved {
  --verification-color: var(--verification-approved);
}

.inline-status-processing {
  --verification-color: var(--verification-processing);
}

.inline-status-pending,
.inline-status-restricted {
  --verification-color: var(--verification-restricted);
}

.inline-status-declined {
  --verification-color: var(--verification-declined);
}

.inline-status-review {
  --verification-color: var(--verification-review);
}

.inline-status-unverified {
  --verification-color: var(--verification-unverified);
}

.modal-overlay.ada-registration-form-modal {
  --modal-content-width: 27.5rem;
}

.details-item-link-container {
  display: flex;
  align-items: center;

  .page-link {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    padding: 0.25rem 0.375rem;
    margin: -0.25rem -0.375rem;
    color: var(--primary-color);

    &:hover {
      color: var(--btn-link-hover-color);
    }
  }
}

.dashboard-select-form-container {
  .rkt-form .rkt-form-select {
    background: var(--white-color);
  }

  .rkt-form-label {
    display: none;
  }
}

.field-disable {
  .input-secured-value {
    pointer-events: none;
    cursor: not-allowed;
    background: var(--disabled-field-color);
    border-color: var(--disabled-field-color);
    text-overflow: ellipsis;
  }
}

.ng-select .ng-spinner-loader {
  margin-top: 0.75rem;
}
