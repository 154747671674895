.status-select {
  display: flex;

  .rkt-form {
    width: 100%;
    max-width: 17.75rem;
  }

  .rkt-form .rkt-form-select {
    background: var(--white-color);
    border-color: var(--white-color);
    width: 100%;
  }

  .rkt-form .rkt-form-select:not(.ng-select-opened, .is-invalid, .ng-select-disabled):hover {
    border-color: var(--table-filter-btn-hover-border-color);
    color: var(--text-primary-color);

    .ng-placeholder {
      color: var(--text-primary-color);
    }
  }

  .rkt-form .rkt-form-select.ng-select-opened {
    border-color: var(--primary-color);
    color: var(--text-primary-color);

    .ng-placeholder {
      color: var(--text-primary-color);
    }
  }

  .rkt-form .rkt-form-select.ng-select .ng-placeholder {
    color: var(--text-secondary-color);
  }

  .rkt-value-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .indicator {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    text-align: center;
    border-radius: 50%;
    background: var(--primary-color);
    font-size: 0.75rem;
    line-height: 1.7;
    color: var(--white-color);
  }

  .ng-select .ng-select-container .ng-value-container {
    position: relative;
  }
}

.ng-dropdown-panel.rkt-form-select-status-dropdown {
  &.rkt-form-assignee-filter-dropdown {
    width: 18.75rem !important;
    margin-left: -9.0625rem;
  }

  .ng-dropdown-panel-items {
    max-height: calc(100vh - 25rem);
  }

  .option-content {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    height: 2.3125rem;
  }

  .option-checkbox {
    flex-shrink: 0;
  }

  .rkt-value-checkbox-icon {
    font-size: 1.5rem;
    color: var(--value-empty-color);
  }

  .ng-option-selected .rkt-value-checkbox-icon {
    color: var(--primary-color);
  }

  .option-label {
    flex: 1;
    overflow: hidden;
  }

  .employee-name {
    color: var(--text-primary-color);
    font-size: 0.875rem;
    line-height: 1.428;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .employee-email {
    color: var(--text-secondary-color);
    font-size: 0.75rem;
    line-height: 1.416;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
