:root {
  --table-row-line-color: #f7f7f780;
  --table-details-row-line-color: #f7f7f7;
}

.multi-leg-transaction-list {
  .header-cell-createdAt .header-cell-inner,
  .body-cell-createdAt .body-cell-inner,
  .placeholder-cell-inner {
    padding-left: 0.25rem;
  }

  .body-cell-creditLegsCount .body-cell-inner,
  .body-cell-debitLegsCount .body-cell-inner {
    padding: 0;

    .body-cell-value {
      padding: 0.375rem 0.25rem;
      border-radius: 4px;
      background: rgb(43 159 173 / 8%);
      border: 1px solid rgb(43 159 173 / 50%);
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.25;
    }

    .body-cell-value.no-value {
      display: none;
    }
  }
}

.mlt-activity-details-row {
  border-top: 2px solid var(--table-row-line-color);
  padding: 0.75rem 3rem 0;
  display: flex;
  flex-direction: column;

  .col-head-row {
    display: flex;
    color: var(--text-secondary-color);
    font-size: 0.875rem;
    line-height: 1.357;
    padding-bottom: 0.8125rem;
    border-bottom: 1px solid var(--table-details-row-line-color);

    .col:not(:last-child) {
      padding-right: 1.25rem;
    }

    .col-head-row {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .col-body-row {
    display: flex;
    font-size: 0.875rem;
    line-height: 1.357;
    color: var(--text-primary-color);
    padding: 0.75rem 0;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid var(--table-details-row-line-color);
    }

    .col:not(:last-child) {
      padding-right: 1.25rem;
    }

    .col-details {
      background: var(--container-accent-bg-color);
      padding: 0.75rem;
      border-radius: 6px;
      display: flex;
    }
  }

  .col-type {
    flex: 168;
  }

  .col-processed {
    flex: 120;
  }

  .col-amount-and-details {
    flex: 668;
    display: flex;
    flex-direction: column;
  }

  .col-amount {
    flex: 188;
    text-align: right;
  }

  .col-details {
    flex: 480;
  }

  .muted {
    color: var(--value-empty-color);
  }

  .highlighted {
    &::after {
      display: none;
    }
  }

  .highlighted:not(.error) {
    color: var(--primary-color);
  }

  .highlighted.error {
    color: var(--error-color);
  }

  .col-details-inner {
    display: inline-flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    font-weight: 600;
    color: var(--text-secondary-color);
  }

  .account,
  .amount,
  .solution {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .account {
    gap: 0.5rem;
  }

  .amount {
    gap: 0.25rem;
  }

  .solution {
    gap: 0.25rem;
    border-left: 1px solid var(--value-empty-color);
    padding-left: 0.5rem;
    color: var(--text-primary-color);
  }

  .solution-icon {
    color: var(--text-secondary-color);
  }
}

.multi-leg-transaction-details-page {
  display: flex;
  gap: 2.5rem;

  .details-section-title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375;
    color: var(--text-primary-color);
    margin-bottom: 0.75rem;
    position: relative;
  }

  .multi-leg-title {
    font-size: 0.9375rem;
    line-height: 1.4;
    color: var(--text-primary-color);
    font-weight: 600;
    display: flex;
    gap: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.625rem 1rem;
    background: #ffb25733;
    border-radius: 0.5625rem;
    border: 1px solid #ffb257;
  }

  .multi-leg-title-icon {
    width: 1.25rem;
    height: 1.25rem;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZmlsbD0iI0ZGQjI1NyIgZD0iTTIwIDEyYzAgMS41ODIyLS40NjkyIDMuMTI5LTEuMzQ4MiA0LjQ0NDYtLjg3OTEgMS4zMTU2LTIuMTI4NSAyLjM0MDktMy41OTAzIDIuOTQ2NC0xLjQ2MTguNjA1NS0zLjA3MDQuNzY0LTQuNjIyMi40NTUzLTEuNTUxODctLjMwODctMi45NzczMy0xLjA3MDYtNC4wOTYxNS0yLjE4OTQtMS4xMTg4Mi0xLjExODktMS44ODA3NS0yLjU0NDMtMi4xODk0My00LjA5NjItLjMwODY4LTEuNTUxOC0uMTUwMjUtMy4xNjA0LjQ1NTI1LTQuNjIyMTcuNjA1NS0xLjQ2MTgxIDEuNjMwODgtMi43MTEyNCAyLjk0NjQ3LTMuNTkwMjlDOC44NzEwMyA0LjQ2OTE5IDEwLjQxNzggNCAxMiA0YzIuMTIxNyAwIDQuMTU2Ni44NDI4NSA1LjY1NjkgMi4zNDMxNUMxOS4xNTcxIDcuODQzNDQgMjAgOS44NzgyNyAyMCAxMlptLTggMS42MTI4Yy0uMjkzNCAwLS41ODAyLjA4Ny0uODI0MS4yNXMtLjQzNC4zOTQ3LS41NDYzLjY2NTdjLS4xMTIzLjI3MTEtLjE0MTcuNTY5My0uMDg0NC44NTcuMDU3Mi4yODc4LjE5ODUuNTUyMS40MDU5Ljc1OTUuMjA3NS4yMDc1LjQ3MTguMzQ4OC43NTk1LjQwNi4yODc4LjA1NzIuNTg2LjAyNzkuODU3LS4wODQ0LjI3MTEtLjExMjMuNTAyOC0uMzAyNC42NjU3LS41NDYzLjE2My0uMjQ0LjI1LS41MzA4LjI1LS44MjQxIDAtLjM5MzQtLjE1NjItLjc3MDctLjQzNDQtMS4wNDg5LS4yNzgyLS4yNzgyLS42NTU1LS40MzQ1LTEuMDQ4OS0uNDM0NVptLTEuNDA4OC01LjMzMzMuMjM5MyA0LjM4NzJjLjAwNTMuMDk4OS4wNDgzLjE5MjEuMTIwMi4yNjAzLjA3MTkuMDY4My4xNjcyLjEwNjMuMjY2My4xMDYzaDEuNTY2N2MuMDk5LS4wMDAyLjE5NDEtLjAzODMuMjY1OS0uMTA2NS4wNzE3LS4wNjgyLjExNDctLjE2MTMuMTE5OS0uMjYwMWwuMjM5My00LjM4NzJjLjAwMjktLjA1MjUzLS4wMDUtLjEwNTA4LS4wMjMxLS4xNTQ0Ni0uMDE4Mi0uMDQ5MzgtLjA0NjItLjA5NDU1LS4wODIzLS4xMzI3NS0uMDM2Mi0uMDM4MTktLjA3OTgtLjA2ODYyLS4xMjgxLS4wODk0MS0uMDQ4My0uMDIwOC0uMTAwNC0uMDMxNTMtLjE1My0uMDMxNTVoLTIuMDQ0NmMtLjA1MjYtLjAwMDAxLS4xMDQ3LjAxMDcxLS4xNTMuMDMxNS0uMDQ4NC4wMjA3OS0uMDkxOS4wNTEyMS0uMTI4MS4wODk0MS0uMDM2Mi4wMzgyLS4wNjQyLjA4MzM4LS4wODIzLjEzMjc3LS4wMTgxLjA0OTM5LS4wMjYuMTAxOTYtLjAyMzEuMTU0NDlaIi8+Cjwvc3ZnPgo=');
    flex-shrink: 0;
    background-repeat: no-repeat;
  }

  .additional-details-sections {
    display: flex;
    gap: 40px 80px;
    flex-wrap: wrap;
  }

  .additional-details-title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4375;
    margin-bottom: 1.25rem;
  }

  .additional-details-inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1 1 0;

    .details-item {
      display: flex;
      gap: 1rem;
      font-size: 0.9375rem;
      line-height: 1.4;
      font-weight: 400;
    }

    .details-label {
      width: 8.125rem;
      color: var(--text-secondary-color);
    }

    .details-value {
      flex: 1;
      min-width: 8.125rem;
      font-weight: 600;
    }

    .details-value.empty-value {
      font-weight: 400;
      color: var(--value-empty-color);
    }

    .details-value-estimation {
      margin-top: 0.25rem;
      font-size: 0.75rem;
      line-height: 1.214;
      color: var(--text-tertiary-color);
    }

    .details-value-estimation .strong {
      font-weight: 600;
      color: var(--text-secondary-color);
    }
  }

  .transaction-details {
    flex: 1;
    min-width: 0;
  }

  .history-column {
    width: 21.5rem;
    flex-shrink: 0;
  }

  .status-tag {
    .loading-placeholder {
      height: 1.625rem;
      width: 4.6875rem;
      border-radius: 30px;
    }
  }

  .amount-row {
    margin-bottom: 1.25rem;

    .amount-label {
      color: var(--text-secondary-color);
      font-size: 0.8125rem;
      line-height: 1.384;
      font-weight: 400;
      margin-bottom: 0.25rem;
    }

    .amount-value {
      color: var(--text-primary-color);
      font-size: 2.25rem;
      line-height: 1.36;
      font-weight: 700;
    }
  }

  .from-to-row {
    display: flex;
    gap: 1.25rem 5rem;
    flex-wrap: wrap;
  }

  .from-to-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.5rem;
    position: relative;

    .title {
      color: var(--text-primary-color);
      font-size: 0.875rem;
      line-height: 1.428;
      font-weight: 600;
    }
  }

  .from-to-row .from-to-item:first-child::after {
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0MCA0MSI+CiAgPGNpcmNsZSBjeD0iMjAiIGN5PSIyMC4zNjI4IiByPSIyMCIgZmlsbD0iIzJCOUZBRCIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xMiAyMS4zNjM1aDEyLjE2OTVsLTUuNTg5NyA1LjU4OTQgMS40MTk2IDEuNDA5OSA4LjAwMDYtOC04LjAwMDYtOC0xLjQxIDEuNDA5OSA1LjU4MDEgNS41OTA1SDEydjIuMDAwM1oiLz4KPC9zdmc+Cg==');
    background-size: 2.5rem 2.5rem;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    left: 100%;
    top: 9.375rem;
    margin: -0.3125rem 0 0 1.5rem;
  }

  .item-body {
    background: var(--container-accent-bg-color);
    border-radius: 9px;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .item-body-row {
    --value-color: var(--text-primary-color);

    display: flex;
    gap: 0 1.25rem;
    font-size: 0.9375rem;
    line-height: 1.428;
    font-weight: 600;

    &.item-body-row-with-separator {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var(--default-separator-color);
    }

    &.item-body-row-tall {
      min-height: 3.75rem;
    }

    &.empty-value {
      --value-color: var(--value-empty-color);

      .value {
        font-weight: 400;
      }
    }

    &.item-row-id {
      --value-color: var(--text-secondary-color);

      font-size: 0.875rem;
      line-height: 1.428;
      font-weight: 400;
    }

    .label {
      color: var(--text-secondary-color);
      width: 8.125rem;
      flex-shrink: 0;
    }

    .value {
      color: var(--value-color);
      flex: 1;
      min-width: 8.125rem;
      overflow-wrap: anywhere;
      word-break: normal;

      .empty-value {
        color: var(--value-empty-color);
        font-weight: 400;
      }

      .rkt-icon {
        font-size: 1rem;
        margin-right: 0.25rem;
        align-self: center;
      }
    }

    .placeholder {
      display: flex;
      flex: 1;

      .placeholder-wrapper {
        flex: 1;
      }

      .loading-placeholder {
        width: auto;
        flex: 1;
        max-width: 12.5rem;
      }
    }
  }

  .create-update-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem 5rem;
  }

  .additional-details {
    border-top: 8px solid var(--body-bg-color);
    border-bottom: 8px solid var(--body-bg-color);
    margin: 1.25rem -1.5rem 0;
    padding: 1.25rem 1.625rem;
  }

  .additional-details + .transaction-metadata {
    margin-top: 0;
  }

  .transaction-metadata {
    border-bottom: 8px solid var(--body-bg-color);
    margin: 0 -1.5rem 0.75rem;
    padding: 1.25rem 1.625rem;
    margin-top: 1.25rem;
  }

  .leg-amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.875rem;
    line-height: 1.428;
    font-weight: 600;
    color: var(--text-primary-color);

    .pending-amount:not(.cleared) {
      color: var(--value-empty-color);
    }

    .pending-amount.cleared {
      color: var(--primary-color);
    }

    .pending-amount.canceled {
      color: var(--error-color);
    }
  }
}

.multi-leg-transaction-edit-modal {
  --modal-content-width: 68.5rem;

  .modal-body {
    padding: 0;
  }

  .modal-title {
    color: var(--text-primary-color);
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 700;
    padding: 1.25rem 1.75rem 0.75rem;
  }

  .delivery-speed-header {
    padding-top: 0 !important;
    height: 2.5rem !important;
  }

  .create-transaction-form {
    .create-transaction-form-header {
      flex-direction: row;
      padding: 1.25rem 1.75rem 0;
      gap: 5rem;

      .amount-row,
      .amount-options-row {
        flex: 1;
        min-width: 0;
      }

      .amount-options-row {
        display: flex;
      }

      &::before {
        border-radius: 0;
        height: 8.25rem;
        background: linear-gradient(147.36deg, #2b9fad -17.64%, #30747c 43.95%, #175770 124.4%);
      }
    }

    .transaction-amount-options-title {
      text-align: left;
    }

    .transaction-amount-options-wrapper {
      padding: 1rem 1.25rem 1.25rem;
    }

    .create-transaction-form-body {
      padding: 1.25rem 1.75rem;
    }
  }

  .create-transaction-form.mid-segment .create-transaction-form-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.cancel-recovery-financial-account-modal {
  --modal-content-width: 68.5rem;

  .modal-body {
    padding: 0;
  }

  .modal-title {
    color: var(--text-primary-color);
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 700;
    padding: 1.75rem 2.25rem 0.75rem;
  }

  .modal-subtitle {
    padding: 0 2.25rem 1.25rem;
    color: var(--text-secondary-color);
    font-size: 0.9375rem;
    line-height: 1.4;
    font-weight: 400;
  }

  .cancel-transaction-form {
    .cancel-transaction-form-header {
      padding: 1.75rem;
      justify-content: center;
      align-items: center;
      background: linear-gradient(147.36deg, #2b9fad -17.64%, #30747c 43.95%, #175770 124.4%);
      height: 8.3125rem;
      display: flex;
    }

    .amount {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      color: var(--white-color);

      .label {
        font-size: 0.875rem;
        line-height: 1.428;
        font-weight: 400;
      }

      .value {
        font-size: 2.25rem;
        line-height: 1.361;
        font-weight: 700;
      }
    }

    .transaction-amount-options-title {
      text-align: left;
    }

    .transaction-amount-options-wrapper {
      padding: 1rem 1.25rem 1.25rem;
    }
  }

  .cancel-transaction-form-body {
    padding: 1.25rem 2.25rem 1.75rem;
  }

  .cancel-transaction-form-footer {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid var(--default-border-color);
    display: flex;
    gap: 5rem;

    .create-transaction-note {
      flex: 1;
    }

    .buttons {
      flex: 1;
      gap: 1.5rem;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .create-transaction-note-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .create-transaction-note-label {
      color: var(--text-primary-color);
      font-size: 0.8125rem;
      line-height: 1.384;
      font-weight: 400;
    }

    .create-transaction-note-textarea {
      background: var(--container-accent-bg-color);
      border: 1px solid var(--container-accent-bg-color);
      height: 6.25rem;
      border-radius: 4px;
      padding: 0.75rem 1.25rem;
      resize: none;
      color: var(--text-primary-color);
      font-size: 0.9375rem;
      line-height: 1.33;

      &:hover {
        border-color: var(--default-border-color);
      }

      &:focus {
        outline: none;
        border-color: var(--primary);
        background: var(--white-color);
      }
    }
  }

  .to-account-title {
    font-size: 1rem;
    line-height: 1.4375;
    color: var(--text-primary-color);
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  .list-page-controls {
    --table-filter-btn-border-color: #e2e3e4;

    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }

  .rkt-table-search {
    --input-border-color: #e2e3e4;
  }

  .two-line-col-item-first {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.4;
    margin-bottom: 0.25rem;
  }

  .two-line-col-item-second {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.417;
    color: var(--table-text-secondary-color);
  }

  .body-cell-accountHolderInfo {
    .two-line-col-item-first {
      font-weight: 600;
    }
  }

  .rkt-table .datatable-footer {
    border-top: none !important;
  }

  .ngx-datatable .datatable-footer .datatable-footer-inner {
    align-items: flex-end;
  }

  .rkt-table .datatable-footer .rkt-table-pager {
    border-top: 2px solid rgb(247 247 247 / 50%);
    padding-top: 1.125rem;
  }

  .no-pagination,
  .empty-list-loading {
    .rkt-table .datatable-footer .rkt-table-pager {
      display: none;
    }
  }

  .table-container {
    height: 25.9375rem;
  }

  .rkt-table .datatable-body-row-disabled .two-line-col-item-second {
    color: var(--table-disabled-row-text-color);
  }

  .to-account-options {
    display: flex;
    gap: 1.25rem;
    align-items: center;
    margin-bottom: 0.75rem;
  }

  .to-account-options-title {
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.384;
    color: var(--text-primary-color);
    margin-bottom: 0.75rem;
    text-align: center;
  }

  .to-account-option-radio-input-label {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .to-account-option-item {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .to-account-option-item-value {
    font-size: 0.9375rem;
    line-height: 1.4;
    color: var(--text-primary-color);
    font-weight: 400;
  }

  .to-account-option-radio-input {
    border: 2px solid #bababa;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    position: relative;
    padding: 0;
    flex-shrink: 0;
    margin: 0.25rem;
  }

  .to-account-option-item:not(.disabled):hover .to-account-option-radio-input,
  .to-account-option-radio-input.checked {
    border-color: var(--primary);
  }

  .to-account-option-item:not(.disabled):hover .to-account-option-radio-input:after,
  .to-account-option-radio-input.checked:after {
    width: 0.5rem;
    height: 0.5rem;
    content: '';
    position: absolute;
    left: 0.125rem;
    top: 0.125rem;
    background: var(--primary);
    border-radius: 50%;
  }

  .to-account-option-item:not(.disabled) .to-account-option-radio-input-label {
    cursor: pointer;
  }

  .default-account {
    background: #f9f9f9;
    border-radius: 8px;
    padding: 0.75rem 1.25rem;
    color: var(--text-secondary-color);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .default-account-row {
      display: flex;
      gap: 1.25rem;
      flex-wrap: wrap;
    }

    .default-account-item {
      font-size: 0.875rem;
      display: flex;
      gap: 0.25rem;
      align-items: center;
    }

    .rkt-icon {
      font-size: 1.5rem;
      flex-shrink: 0;
      margin-left: 1.25rem;
    }

    .default-account-item-accent {
      font-weight: 600;
    }

    .default-account-item-offset-left {
      margin-left: 2rem;
    }
  }
}

.cancel-recovery-financial-account-review-modal {
  --modal-content-width: 56.25rem;

  .modal-body {
    padding: 1.75rem 2.25rem;
  }

  .modal-title {
    color: var(--text-primary-color);
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 700;
  }

  .cancel-transaction-details {
    display: flex;
    gap: 1.25rem;
  }

  .cancel-transaction-amount-to-icon {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0MCA0MCI+CiAgPGNpcmNsZSBjeD0iMjAiIGN5PSIyMCIgcj0iMjAiIGZpbGw9IiMyQjlGQUQiLz4KICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTIgMjFoMTIuMTdsLTUuNTkgNS41OUwyMCAyOGw4LTgtOC04LTEuNDEgMS40MUwyNC4xNyAxOUgxMnYyWiIvPgo8L3N2Zz4K');
    background-size: 2.5rem 2.5rem;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    margin-top: 4.5625rem;
  }

  .amount,
  .to-account {
    min-width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .section-label {
    color: var(--text-primary-color);
    font-size: 0.875rem;
    line-height: 1.428;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .amount-value {
    background: linear-gradient(147.36deg, #2b9fad -17.64%, #30747c 43.95%, #175770 124.4%);
    border-radius: 9px;
    height: 9.5625rem;
    padding: 1.25rem;
    display: flex;
    align-items: center;
    color: var(--white-color);
    font-size: 2.25rem;
    line-height: 1.361;
    font-weight: 700;
    flex: 1;
  }

  .rows {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 0;
    background: var(--container-accent-bg-color);
    border-radius: 8px;
    padding: 1rem 1.25rem;
  }

  .row {
    display: flex;
    gap: 0 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.4;
    font-weight: 600;
    flex-wrap: wrap;

    &.row-with-top-border {
      border-top: 1px solid var(--default-border-color);
      padding-top: 0.5rem;
    }

    .label {
      color: var(--text-secondary-color);
      width: 8.125rem;
      font-weight: 400;
      flex-shrink: 0;
    }

    .value {
      color: var(--value-color);
      flex: 1;
      min-width: 11.875rem;
      overflow-wrap: break-word;
    }

    .empty-value {
      color: var(--value-empty-color);
    }

    .value-with-icon {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      .rkt-icon {
        font-size: 1rem;
        flex-shrink: 0;
      }
    }
  }

  .notes {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-bottom: 1.5rem;

    .notes-label {
      color: var(--text-secondary-color);
      font-size: 0.8125rem;
      line-height: 1.384;
      font-weight: 400;
    }

    .notes-note {
      font-size: 0.9375rem;
      line-height: 1.4;
      font-weight: 400;
      color: var(--text-primary-color);
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 1480px) {
  .multi-leg-transaction-details-page .from-to-row .from-to-item:first-child::after {
    transform: rotate(90deg);
    position: relative;
    top: 0.625rem;
    left: calc(50% - 2.5rem);
  }
}
