:root {
  --filter-menu-border-color: #e2e3e4;
  --filter-background-color: #ffffff;
  --filter-toggler-button: #4a4a4a;
  --filter-toggler-button-hover: #2b9fad;
  --filter-name-text-color: #111111;
  --filter-selected-custom-range-text-color: #175770;
}

.rkt-filter-menu {
  width: 17.5rem;
  border-radius: 8px;
  border: 1px solid var(--filter-menu-border-color);
  padding: 0.75rem 0 0.75rem 1rem;
  position: fixed;
  z-index: 1001;
  background: var(--filter-background-color);
  top: 3.75rem;
  display: flex;
  flex-direction: column;
}

.rkt-filter-menu-content {
  overflow-x: auto;
  flex: 1;
}

.filter-scroll-item {
  overflow: hidden;
}

.filter-scroll-item:last-child .rkt-filter-item-container {
  border: none;
}

.rkt-filter-menu-buttons {
  border-top: 1px solid var(--filter-menu-border-color);
  display: flex;
  padding-top: 0.75rem;
  margin-right: 1rem;
  gap: 0.625rem;
  align-items: center;

  .rkt-button {
    flex: 1;
    justify-content: center;
  }
}

.rkt-filter-item-container {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
  margin-right: 1rem;
  border-bottom: 1px solid var(--filter-menu-border-color);

  .filter-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filter-name-wrapper {
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .filter-name-wrapper:hover .filter-toggler {
    color: var(--filter-toggler-button-hover);
  }

  .filter-toggler {
    font-size: 1rem;
    margin-right: 0.25rem;
    color: var(--filter-toggler-button);
  }

  .filter-name {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.428;
    color: var(--filter-name-text-color);
  }

  .filter-reset-btn {
    padding: 0;
    font-size: 0.875rem;
    line-height: 1.428;
  }

  .rkt-form-radio-label,
  .rkt-form-checkbox-label {
    height: 2.5rem;
  }

  .rkt-form-checkbox-value {
    line-height: 1.2 !important;
  }

  .rkt-form-radio {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.edit-icon {
  cursor: pointer;
}

.selected-range-values {
  padding: 0 1rem 0 1.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428;
  color: var(--filter-selected-custom-range-text-color);
}
