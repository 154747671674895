:root {
  --list-page-title-color: #111111;
}

.list-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  gap: 2.5rem;
}

.list-page-title {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.375;
  color: var(--list-page-title-color);
}

.list-page-title-sm {
  font-size: 1.125rem;
  line-height: 1.5;
}

.list-page-controls {
  display: flex;
  gap: 1.25rem;
  flex: 1;
  justify-content: flex-end;
  flex-wrap: wrap;

  .rkt-table-search {
    max-width: 33.5rem;
    flex: 1;
  }
}

.add-entity-btn .rkt-icon {
  align-self: center;
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

.add-entity-btn.rkt-button-secondary .rkt-icon {
  color: var(--primary);
}
