// legacy colors
$primary-white: #ffffff;
$primary-black: #000000;
$transparent: transparent;
$ux-charcoal-black: #333333;
$ux-teal-green: #2b9fad;
$ux-cornflower-blue: #2b9fad;
$ux-alert: #d90125;
$ux-gray2: #e2e3e4;
$ux-gray3: #bababa;
$ux-med-gray-1: #707070;
$ux-med-gray-2: #6a7071;
$ux-boulder-gray: #757575;
$smoke-white: #f6f6f6;
$quill-grey: #d6d6d6;
$dull-orange: #e8842e;
$natural-gray: #898989;
$iron-gray: #ced4da;
$mercury-gray: #e7e8e9;
$davy-gray: #495057;
$ux-charcoal-black-60: rgba($ux-charcoal-black, 0.6);
$primary-black-56: rgba($primary-black, 0.56);
$iron-gray-60: rgba($iron-gray, 0.6);
$ux-med-gray-1-34: rgba($ux-med-gray-1, 0.34);
$primary-white-0: rgba($primary-white, 0);
$primary-white-10: rgba($primary-white, 0.1);
$primary-white-04: rgba($primary-white, 0.04);
$primary-white-20: rgba($primary-white, 0.2);
$primary-white-35: rgba($primary-white, 0.35);

// new ux colors
$ux-text-black: #111111;
$ux-text-gray: #4a4a4a;
$ux-success-green: #43ca21;
$ux-grey-white: #f7f7f7;
$ux-light-teal: #175770;
$ux-light-teal-green: #d9f4d3;
