:root {
  --container-bg-color: #ffffff;
  --title-color: #111111;
  --address-container-bg-color: #f7f7f7;
  --address-container-border-color: #e2e3e4;
  --note-field-text-color: #111111;
  --form-field-separator-color: #e2e3e4;
  --identity-select-label-color: #4a4a4a;
}

.entity-details-container {
  background: var(--container-bg-color);
  padding: 1.25rem 1.5rem;
  border-radius: 0.5rem;
}

.profile-details-container {
  border-radius: 0 0 8px 8px;
}

.entity-details-title {
  color: var(--title-color);
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 0.75rem;
}

.entity-details-address-container {
  background: var(--address-container-bg-color);
  padding: 0.75rem 1rem;
  border-radius: 8px;
  margin: 0 -1rem 0.75rem;

  &.no-offset-bottom {
    margin-bottom: 0;
  }

  .details-row {
    display: flex;
    gap: 12px 48px;
    flex-wrap: wrap;
  }

  .details-item-container {
    flex: 0 0 auto;
  }
}

.details-row {
  display: grid;
  gap: 16px 66px;
  margin-right: 1.25rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.details-row.two-cols-row {
  grid-template-columns: 1fr 1fr;
}

.details-item-container {
  flex: 1;
  min-width: 0;
}

.details-row-with-offset {
  margin-bottom: 12px;
}

.entity-details-address-title {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.428;
  color: var(--title-color);
  margin-bottom: 0.5rem;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid var(--address-container-border-color);
}

.details-page-edit-form-error {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.384;
  color: var(--error-color);
  margin-top: 1.25rem;
  white-space: pre-wrap;
}

.details-page-edit-form {
  .rkt-form .rkt-form-row-group {
    margin: 0;
  }

  .rkt-form-control {
    margin-bottom: 0.5rem;
  }

  .note-container {
    margin-top: 0.5rem;
  }

  .note-field-wrapper {
    --note-wrapper-border-color: #e2e3e4;
  }

  .note-field {
    resize: none;
    padding: 0.6875rem 1.1875rem;
    color: var(--note-field-text-color);
    font-size: 0.9375rem;
    line-height: 1.33;
    height: 5.75rem;
    font-weight: 400;
    flex: 1;
    min-width: 0;
  }

  .note-field:focus {
    outline: 0;
    border: none;
  }
}

.integrated-bank-account-form .rkt-form .rkt-form-row-group {
  .rkt-form-row {
    margin-top: 1.25rem;
  }

  .account-type-row {
    margin-top: 0.5rem;
  }
}

.add-customer .rkt-form .rkt-form-row-group {
  .customer-additional-info-item {
    width: 11rem;
  }

  .customer-details-separator {
    border-bottom: 1px solid var(--form-field-separator-color);
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .address-field-label {
    .rkt-form-label {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.4375;
      color: var(--text-primary-color);
      margin-top: 1.25rem;
    }
  }
}

.currency-select-field {
  .rkt-form-label {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4375;
    color: var(--text-primary-color);
    margin-right: 1rem;
  }

  .rkt-form-field {
    flex-direction: row;
  }
}

.identity-verification .rkt-form .rkt-form-row-group {
  .rkt-form-label {
    color: var(--identity-select-label-color);
    margin-bottom: 0.8438rem;
  }
}

.id-row {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428;
  color: var(--text-primary-color);
  margin-bottom: 0.75rem;
}

.account-verification-body {
  .details-item {
    .details-item-label,
    .details-item-value {
      color: var(--white);
    }
  }

  .account-verification-fields .details-item-label {
    margin-bottom: 0.25rem;
  }
}
