:root {
  --move-how-notes-textarea-background-color: #f9f9f9;
  --how-type-item-border-color: #e2e3e4;
  --how-type-item-text-color: #111111;
  --how-type-direction-item-background-color: #f7f7f7;
  --how-type-item-disabled-text-color: #bababa;
  --how-type-item-icon-color: #4a4a4a;
}

.move-note-section {
  .note-field-wrapper {
    background: var(--white-color) !important;
    border: none !important;
    border-radius: 4px !important;
  }

  .move-how-notes-textarea {
    width: 100%;
    background: var(--move-how-notes-textarea-background-color) !important;
    height: 6.25rem;
    border-radius: 4px;
    padding: 0.75rem 1.25rem;
    resize: none;

    &:focus {
      outline: none;
      border: 1px solid var(--primary);
    }
  }
}

// Move How Solution and Direction START
.move-how-type-items {
  display: flex;
  gap: 1.25rem 0.75rem;
  padding: 0.75rem 1rem;

  .type-item {
    width: 7.25rem;
    height: 4.75rem;
    padding: 0.75rem 0.5rem;
    border-radius: 6px;
    border: 1px solid var(--how-type-item-border-color);
    flex-shrink: 0;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
  }

  &.move-money-direction {
    .type-item {
      background: var(--how-type-direction-item-background-color);
    }
  }

  &:not(.move-money-direction) {
    background: #f9f9f9;
    border-radius: 8px;
  }

  .type-item:not(.disabled-type):hover,
  .type-item.active {
    border-color: var(--primary);
    color: var(--primary);
    cursor: pointer;

    .rkt-icon {
      color: var(--primary);
    }
  }

  .type-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--how-type-item-icon-color);
  }

  .type-label {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.428;
    color: var(--how-type-item-text-color);
  }

  .type-item.disabled-type {
    user-select: none;
    pointer-events: none;
    opacity: 0.4;
  }
}
// Move How Solution and Direction END
