.rkt-form-checkbox {
  .rkt-form-checkbox-label {
    display: flex;
    align-items: center;
  }

  .rkt-form-checkbox-label:not(.disabled) {
    cursor: pointer;
    color: var(--text-primary-color);
  }

  .rkt-form-checkbox-label.disabled {
    color: var(--form-field-placeholder-color);

    .rkt-form-checkbox-icon {
      color: var(--form-field-placeholder-color);
    }
  }

  .rkt-form-checkbox-icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
    color: var(--form-field-placeholder-color);
    flex-shrink: 0;
  }

  .rkt-form-checkbox-value {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.6;
  }

  .rkt-form-checkbox-input:checked:not(:disabled) + .rkt-form-checkbox-icon {
    color: var(--primary);
  }

  .rkt-form-checkbox-label:not(.disabled):hover {
    .rkt-form-checkbox-icon {
      color: var(--primary);
    }
  }
}

.rkt-form .rkt-form-select .ng-clear-wrapper {
  opacity: 0.3;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  margin-top: 0.75rem;
  margin-right: 0.8125rem;
}
