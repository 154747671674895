:root {
  --table-body-limit-column-color: var(--primary);
  --table-body-empty-value-color: #bababa;
  --table-filter-btn-color: #4a4a4a;
  --table-filter-btn-hover-color: #111111;
  --table-filter-btn-bg-color: #ffffff;
  --table-filter-btn-border-color: #ffffff;
  --table-filter-btn-hover-border-color: #757575;
  --table-filter-icon-color: #4a4a4a;
  --table-filter-counter-color: #ffffff;
  --table-filter-counter-bg-color: var(--primary);
  --table-text-secondary-color: #4a4a4a;
  --schedule-recurring-tag-background: #d2ebfa;
  --table-cell-delete-button-color: #ff6e6e;
}

.header-cell-limit-column .header-cell-value {
  color: var(--table-body-limit-column-color);
}

.body-cell-limit-column {
  .body-cell-value-empty {
    color: var(--table-body-empty-value-color);
  }

  .body-cell-value:not(.body-cell-value-empty) {
    color: var(--table-body-limit-column-color);
  }
}

.header-cell-status .header-cell-value,
.header-cell-state .header-cell-value {
  padding-right: 0.25rem;
}

.rkt-table .body-cell-value.no-value {
  color: var(--table-body-empty-value-color) !important;
}

.table-header {
  height: 2.75rem;
  margin-bottom: 1.25rem;
  display: flex;
}

.table-header-offset-sm {
  margin-bottom: 0.75rem;
}

.table-header-offset {
  margin-bottom: 1rem;
}

.table-header-with-controls {
  justify-content: space-between;
}

.table-header-search {
  flex: 1;
  max-width: 33.5rem;
}

.table-header-filters {
  margin-left: 1.25rem;
  display: flex;
  align-items: center;
}

.table-header-filter-btn {
  padding: 0.6875rem 0.9375rem;
  height: 2.75rem;
  border: 1px solid var(--table-filter-btn-border-color);
  border-radius: 8px;
  background: var(--table-filter-btn-bg-color);
  color: var(--table-filter-btn-color);
  display: flex;
  font-size: 0.9375rem;
  line-height: 1.4;

  &:not(.opened):hover {
    color: var(--table-filter-btn-hover-color);
    border-color: var(--table-filter-btn-hover-border-color);
  }

  &.opened {
    --table-filter-btn-border-color: var(--primary);

    .rkt-icon {
      color: var(--primary);
    }
  }
}

.table-header-filter-icon {
  font-size: 1.25rem;
  color: var(--table-filter-icon-color);
  margin-right: 0.5rem;
}

.table-header-filter-counter {
  border-radius: 50%;
  background: var(--table-filter-counter-bg-color);
  margin-left: 0.5rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.17;
  color: var(--table-filter-counter-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
}

.table-header-controls {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.table-header-controls.no-title {
  justify-content: flex-start;
  gap: 1.25rem;
}

.rejected-transactions .rkt-table .datatable-body-row:hover .body-cell-inner {
  background: transparent;
}

.rejected-transactions,
.transaction-list,
.debit-auth-list,
.scheduled-transaction-list {
  .two-line-col-item-first {
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.4;
    margin-bottom: 0.3125rem;
  }

  .two-line-col-item-second {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.417;
    color: var(--table-text-secondary-color);
  }
}

.card-transaction-list {
  min-height: 31.25rem;
}

.scheduled-transaction-list {
  .header-cell-transactionType .header-cell-inner,
  .header-cell-scheduleType .header-cell-inner,
  .body-cell-transactionType .body-cell-inner,
  .body-cell-scheduleType .body-cell-inner {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .body-cell-scheduleType .body-cell-value {
    background: var(--status-warning-bg-color);
    padding: 0.125rem 0.5rem;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.416;
    color: var(--text-primary-color);
    display: inline-flex;
    border-radius: 4px;
  }

  .schedule-type-recurring .body-cell-scheduleType .body-cell-value {
    background: var(--schedule-recurring-tag-background);
  }

  .body-cell-transactionType .body-cell-inner {
    position: relative;

    .body-cell-value:not(.no-value) {
      margin-left: 1.75rem;
    }
  }

  .mlt-row .body-cell-transactionType .body-cell-value:not(.no-value)::before,
  .request-row .body-cell-transactionType .body-cell-value:not(.no-value)::before,
  .send-row .body-cell-transactionType .body-cell-value:not(.no-value)::before,
  .reversal-row .body-cell-transactionType .body-cell-value:not(.no-value)::before,
  .return-row .body-cell-transactionType .body-cell-value:not(.no-value)::before,
  .adjustment-row .body-cell-transactionType .body-cell-value:not(.no-value)::before {
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    left: 0.375rem;
    top: 1rem;
    rotate: 180deg;
  }

  .mlt-row .body-cell-transactionType .body-cell-value:not(.no-value)::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMCAyMSI+CiAgPHBhdGggZmlsbD0iI0ZGQjI1NyIgZD0iTTEwIDIuNTgzYy00LjU5NiAwLTguMzMzIDMuNzM4LTguMzMzIDguMzMzIDAgNC41OTYgMy43MzcgOC4zMzQgOC4zMzMgOC4zMzQgNC41OTUgMCA4LjMzMy0zLjczOCA4LjMzMy04LjMzNCAwLTQuNTk1LTMuNzM4LTguMzMzLTguMzMzLTguMzMzWm0wIDMuMjZjMS40NSAwIDIuODA0LjYyIDMuNzU5IDEuNjc3bC45NTEtLjk1MXYyLjUzNmgtMi41MzZsMS4wNzQtMS4wNzRBNC4zMzggNC4zMzggMCAwIDAgMTAgNi41NjlhNC4zNTMgNC4zNTMgMCAwIDAtNC4zNDggNC4zNDdoLS43MjVBNS4wNzkgNS4wNzkgMCAwIDEgMTAgNS44NDRabTAgMTAuMTQ2YTUuMDY0IDUuMDY0IDAgMCAxLTMuNzYtMS42NzVsLS45NS45NXYtMi41MzZoMi41MzZsLTEuMDc0IDEuMDc0QTQuMzQgNC4zNCAwIDAgMCAxMCAxNS4yNjRhNC4zNTMgNC4zNTMgMCAwIDAgNC4zNDgtNC4zNDhoLjcyNEE1LjA3OSA1LjA3OSAwIDAgMSAxMCAxNS45OVoiLz4KPC9zdmc+Cg==');
  }

  .request-row .body-cell-transactionType .body-cell-value:not(.no-value)::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMCAyMCI+CiAgPHBhdGggZmlsbD0iIzQzQ0EyMSIgZD0iTTE4LjMzNCAxMGMwLTQuNTk1LTMuNzM5LTguMzMzLTguMzM0LTguMzMzUzEuNjY3IDUuNDA1IDEuNjY3IDEwYzAgNC41OTUgMy43MzggOC4zMzQgOC4zMzMgOC4zMzQgNC41OTUgMCA4LjMzNC0zLjczOSA4LjMzNC04LjMzNFpNNi43MDMgMTEuMjE0bDEuMTc4LTEuMTc4IDEuMjg2IDEuMjg2VjUuODM0aDEuNjY3djUuNDg4bDEuMjg1LTEuMjg2IDEuMTc5IDEuMTc4TDEwIDE0LjUxMmwtMy4yOTctMy4yOThaIi8+Cjwvc3ZnPgo=');
  }

  .send-row .body-cell-transactionType .body-cell-value:not(.no-value)::before,
  .reversal-row .body-cell-transactionType .body-cell-value:not(.no-value)::before,
  .return-row .body-cell-transactionType .body-cell-value:not(.no-value)::before,
  .adjustment-row .body-cell-transactionType .body-cell-value:not(.no-value)::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMCAyMCI+CiAgPHBhdGggZmlsbD0iI0ZGNkU2RSIgZD0iTTE4LjMzNCAxMGE4LjMzMyA4LjMzMyAwIDEgMC0xNi42NjcgMCA4LjMzMyA4LjMzMyAwIDEgMCAxNi42NjYgMFptLTkuMTY3IDQuMTY3VjguNjc5bC0xLjkxMSAxLjkxLTEuMTc4LTEuMTc4TDEwIDUuNDlsMy45MjMgMy45MjItMS4xNzkgMS4xNzgtMS45MS0xLjkxdjUuNDg4SDkuMTY3WiIvPgo8L3N2Zz4K');
  }
}

.rkt-table .body-cell-deleteBtn .body-cell-inner .icon-button-cell .rkt-icon {
  color: var(--table-cell-delete-button-color) !important;
}

.rkt-table .body-cell-editBtn .body-cell-inner .icon-button-cell .rkt-icon,
.rkt-table .body-cell-downloadBtn .body-cell-inner .icon-button-cell .rkt-icon {
  color: var(-table-filter-btn-hover-color) !important;
}

.header-cell-verification .header-cell-inner,
.body-cell-verification .body-cell-inner {
  padding-right: 0;
}

.body-cell-verification .body-cell-value {
  background: var(--white);
  padding: 0.125rem 0.25rem;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  border-radius: 0.125rem;
  gap: 0.375rem;
  color: var(--verification-color);
}

.verification-approved .body-cell-verification .body-cell-value {
  --verification-color: var(--verification-approved);
}

.verification-processing .body-cell-verification .body-cell-value {
  --verification-color: var(--verification-processing);
}

.verification-pending .body-cell-verification .body-cell-value,
.verification-restricted .body-cell-verification .body-cell-value {
  --verification-color: var(--verification-restricted);
}

.verification-declined .body-cell-verification .body-cell-value {
  --verification-color: var(--verification-declined);
}

.verification-review .body-cell-verification .body-cell-value {
  --verification-color: var(--verification-review);
}

.verification-unverified .body-cell-verification .body-cell-value {
  --verification-color: var(--verification-unverified);
}

.no-items .rkt-table {
  .empty-row {
    padding: 0;
  }

  .ngx-datatable .datatable-footer .datatable-footer-inner {
    display: none;
  }
}

.rkt-table-no-footer.ngx-datatable {
  .datatable-body {
    border-radius: 0 0 8px 8px;
  }

  .datatable-footer .datatable-footer-inner {
    display: none;
  }
}

.empty-exception-list {
  flex-direction: column;
  height: 280px;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 40px;

  .empty-list-icon {
    width: 151px;
    height: 119px;
    background-image: url('/assets/images/empty-exception-list.svg');
    background-repeat: no-repeat;
    background-color: var(--transparent);
    margin-bottom: 20px;
  }

  .empty-list-title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5;
    color: var(--text-primary-color);
    margin-bottom: 4px;
  }

  .empty-list-message {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.428;
    color: var(--text-tertiary-color);
    text-align: center;
  }
}

.exception-list {
  .two-line-col-item-first {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.428;
  }

  .two-line-col-item-second {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.417;
    color: var(--table-text-secondary-color);
  }
}
