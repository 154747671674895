@import 'colors';

$grid-breakpoint-tablet: 1024px;

$body-font: 'Nunito Sans', sans-serif;

$box-shadow-default: 0 3px 6px 0;

$font-size-xxs: 0.75rem;
$font-size-xs: 0.875rem;
$font-size-sm: 1rem;
$font-size-md: 1.125rem;
$font-size-lg: 1.25rem;

$rounded-corners-circle: 50%;

$zIndexUserMenu: 1021;

$zIndexUserModalDropdown: 1055;
