:root {
  --schedule-recurring-background: rgb(43 159 173 / 8%);
  --schedule-recurring-border-color: rgb(43 159 173 / 50%);
  --schedule-recurring-tag-background: #b1e2ff;
}

.transaction-details-page {
  display: flex;
  gap: 2.5rem;

  .transaction-details {
    flex: 1;
    min-width: 0;
  }

  .history-column {
    width: 21.5rem;
    flex-shrink: 0;
  }

  .status-tag {
    .loading-placeholder {
      height: 1.625rem;
      width: 4.6875rem;
      border-radius: 30px;
    }
  }

  .amount-row {
    margin-bottom: 1.25rem;

    .amount-label {
      color: var(--text-secondary-color);
      font-size: 0.8125rem;
      line-height: 1.384;
      font-weight: 400;
      margin-bottom: 0.25rem;
    }

    .amount-value {
      color: var(--text-primary-color);
      font-size: 2.25rem;
      line-height: 1.36;
      font-weight: 700;
    }
  }

  .from-to-row {
    display: flex;
    gap: 1.25rem 5rem;
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
  }

  .from-to-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.5rem;
    position: relative;

    .title {
      color: var(--text-primary-color);
      font-size: 0.875rem;
      line-height: 1.428;
      font-weight: 600;
    }
  }

  .from-to-row .from-to-item:first-child::after {
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0MCA0MSI+CiAgPGNpcmNsZSBjeD0iMjAiIGN5PSIyMC4zNjI4IiByPSIyMCIgZmlsbD0iIzJCOUZBRCIvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xMiAyMS4zNjM1aDEyLjE2OTVsLTUuNTg5NyA1LjU4OTQgMS40MTk2IDEuNDA5OSA4LjAwMDYtOC04LjAwMDYtOC0xLjQxIDEuNDA5OSA1LjU4MDEgNS41OTA1SDEydjIuMDAwM1oiLz4KPC9zdmc+Cg==');
    background-size: 2.5rem 2.5rem;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    left: 100%;
    top: 9.375rem;
    margin: -0.3125rem 0 0 1.5rem;
  }

  .item-body {
    background: var(--container-accent-bg-color);
    border-radius: 9px;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
  }

  .item-body-row {
    --value-color: var(--text-primary-color);

    display: flex;
    gap: 0 1.25rem;
    font-size: 0.9375rem;
    line-height: 1.4;
    font-weight: 600;

    &.item-body-row-with-separator {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var(--default-separator-color);
    }

    &.item-body-row-tall {
      min-height: 4.5rem;
    }

    &.empty-value {
      --value-color: var(--value-empty-color);

      .value {
        font-weight: 400;
      }
    }

    &.item-row-id {
      --value-color: var(--text-secondary-color);

      font-size: 0.875rem;
      line-height: 1.4;
      font-weight: 400;
    }

    .label {
      color: var(--text-secondary-color);
      width: 8.125rem;
      flex-shrink: 0;
    }

    .value {
      color: var(--value-color);
      flex: 1;
      min-width: 8.125rem;
      overflow-wrap: anywhere;
      word-break: normal;

      .empty-value {
        color: var(--value-empty-color);
        font-weight: 400;
      }

      .rkt-icon {
        font-size: 1rem;
        margin-right: 0.25rem;
        align-self: center;
      }
    }

    .two-row-value {
      min-height: 2.625rem;
    }

    .placeholder {
      display: flex;
      flex: 1;

      .placeholder-wrapper {
        flex: 1;
      }

      .loading-placeholder {
        width: auto;
        flex: 1;
        max-width: 12.5rem;
      }
    }
  }

  .create-update-details,
  .additional-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem 5rem;
  }

  .additional-details {
    padding: 1.25rem 0;
  }

  .additional-details-with-border {
    border-top: 1px solid var(--default-border-color);
  }

  .estimate-details {
    border-top: 8px solid var(--body-bg-color);
    border-bottom: 8px solid var(--body-bg-color);
    margin: 1.25rem -1.5rem 0;
    padding: 1.25rem 1.625rem;
  }

  .estimate-details-sections {
    display: flex;
    gap: 40px 80px;
    flex-wrap: wrap;
  }

  .estimate-details-title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4375;
    margin-bottom: 1.25rem;
  }

  .estimate-details-inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1 1 0;

    .details-item {
      display: flex;
      gap: 1rem;
      font-size: 0.9375rem;
      line-height: 1.4;
      font-weight: 400;
    }

    .details-label {
      width: 8.125rem;
      color: var(--text-secondary-color);
    }

    .details-value {
      flex: 1;
      min-width: 8.125rem;
      font-weight: 600;
    }

    .details-value.empty-value {
      font-weight: 400;
      color: var(--value-empty-color);
    }

    .details-value-estimation {
      margin-top: 0.25rem;
      font-size: 0.75rem;
      line-height: 1.214;
      color: var(--text-tertiary-color);
    }

    .details-value-estimation .strong {
      font-weight: 600;
      color: var(--text-secondary-color);
    }
  }

  .transaction-metadata {
    border-top: 8px solid var(--body-bg-color);
    border-bottom: 8px solid var(--body-bg-color);
    margin: 0 -1.5rem 0.75rem;
    padding: 1.25rem 1.625rem;
  }

  .upcoming-transactions {
    border-top: 8px solid var(--body-bg-color);
    margin: 0 -1.5rem;
    padding: 1.25rem 1.625rem;
  }

  .leg-amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.875rem;
    line-height: 1.428;
    font-weight: 600;
    color: var(--text-primary-color);

    .pending-amount:not(.cleared) {
      color: var(--value-empty-color);
    }

    .pending-amount.cleared {
      color: var(--primary-color);
    }

    .pending-amount.canceled {
      color: var(--error-color);
    }
  }

  .details-section-title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375;
    color: var(--text-primary-color);
    margin-bottom: 0.75rem;
  }
}

.schedule-recurring-details {
  display: flex;
  flex-direction: column;
  background: var(--schedule-recurring-background);
  border: 1px solid var(--schedule-recurring-border-color);
  border-radius: 8px;
  margin: -1.25rem -1.5rem 1.25rem;
  padding: 1.25rem 1.5rem;
  color: var(--text-primary-color);

  .title-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1.0625rem;
  }

  .title {
    font-size: 1rem;
    line-height: 1.375;
    color: var(--text-primary-color);
    font-weight: 700;
  }

  .recurring-tag {
    border-radius: 4px;
    padding: 0.125rem 0.5rem;
    background: var(--schedule-recurring-tag-background);
    font-size: 0.75rem;
    line-height: 1.416;
    font-weight: 600;
    color: var(--text-primary-color);

    &.scheduled {
      background: var(--status-warning-bg-color);
    }
  }

  .details-row {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .details-item-label {
    white-space: initial !important;
  }

  .details-item-value {
    --details-value-font-weight: 700;

    font-size: 0.875rem !important;
    line-height: 1.428 !important;
    margin-bottom: 0;
  }
}

.upcoming-transactions-title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4375;
  color: var(--text-primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover .rkt-icon {
    background: var(--action-button-hover-bg-color);
    border-radius: 50%;
    outline: 2px solid var(--action-button-hover-bg-color);
  }

  .rkt-icon {
    font-size: 1.5rem;
  }
}

.previous-transactions {
  margin-top: 0.75rem;
}

.upcoming-transaction-items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.8rem;
}

.upcoming-transaction-item {
  display: flex;
  height: 2.75rem;
  border-radius: 9px;
  background: var(--container-accent-bg-color);
  padding: 0.75rem 1rem;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.428;
  color: var(--text-primary-color);

  .from-to {
    display: flex;
    align-items: center;
    gap: 1.25rem;
  }

  .from,
  .to {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .rkt-icon {
    color: var(--primary-color);
    font-size: 0.75rem;
  }

  .name {
    font-weight: 600;
  }

  .account {
    font-size: 0.75rem;
  }

  .amount {
    font-weight: 700;
  }

  .empty-value {
    color: var(--value-empty-color);
    font-weight: 400;
  }
}

.transaction-metadata {
  .transaction-metadata-title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4375;
    color: var(--text-primary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover .rkt-icon {
      background: var(--action-button-hover-bg-color);
      border-radius: 50%;
      outline: 2px solid var(--action-button-hover-bg-color);
    }

    .rkt-icon {
      font-size: 1.5rem;
    }
  }

  .transaction-metadata-items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.8rem;
  }

  .transaction-metadata-item {
    display: flex;
    height: 2.75rem;
    border-radius: 9px;
    background: var(--container-accent-bg-color);
    padding: 0.75rem 1rem;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.428;
    color: var(--text-primary-color);

    .key {
      flex: 1;
    }

    .value {
      flex: 2;
      font-weight: 600;
    }
  }

  .transaction-metadata-header-item {
    background: rgb(43 159 173 / 10%);
    height: 2.1875rem;
    color: var(--text-secondary-color);

    .value {
      font-weight: 400;
    }
  }

  .transaction-metadata-empty-item {
    font-size: 0.875rem;
    line-height: 1.428;
    padding: 1.25rem 0;
    text-align: center;
  }
}

@media (max-width: 1480px) {
  .transaction-details-page .from-to-row .from-to-item:first-child::after {
    transform: rotate(90deg);
    position: relative;
    top: 0.625rem;
    left: calc(50% - 2.5rem);
  }
}
